import { MODE_AIR, MODE_TRANSFER, TRANSFER_STOP } from '@/lib/constants';
import { RouteLocationInterface, RouteSegmentModeVerb } from '@/types';
import { RouteTransferApiType, SegmentModeType } from '@/types/api-types';
import { getPreferredIdentifier } from '@/utils/helpers';

const formatSegmentDescription = (
  from: RouteLocationInterface | undefined | null,
  to: RouteLocationInterface | undefined | null,
  modePrefix: RouteSegmentModeVerb | string,
  blockMode: SegmentModeType | undefined | null,
  segmentMode: SegmentModeType | undefined | null,
  transferType: RouteTransferApiType | undefined | null,
): string => {
  const fromCode = from ? getPreferredIdentifier(from.identifiers) : null;
  const toCode = to ? getPreferredIdentifier(to.identifiers) : null;

  if (segmentMode === MODE_TRANSFER && fromCode) {
    if (transferType && transferType === TRANSFER_STOP) {
      if (blockMode === MODE_AIR) return `Change planes at ${fromCode.value}`;
      return `Stops at ${fromCode.value}`;
    }
    return `Transfer at ${fromCode.value}`;
  }

  // Port to Port
  if (fromCode && toCode) {
    return `${fromCode.value} to ${toCode.value}`;
  }

  return `${modePrefix} ${toCode ? toCode.value : to?.name}`;
};

export { formatSegmentDescription };
