import { isEmpty } from 'lodash';
import pluralize from 'pluralize';

import { IdentifierApiInterface } from '@/types/api-types';

export const formatStops = (stops: number) => {
  return !stops ? 'No stops' : `${stops} ${pluralize('stop', stops)}`;
};

export const formatTransfers = (transfers: number) => {
  return !transfers ? 'Direct' : `${transfers} ${pluralize('transfer', transfers)}`;
};

/**
 * Stop Location String
 * @param stops - Array of stop locations
 * @returns string of stop codes
 */
export const formatStopLocations = (stops: IdentifierApiInterface[]): string => {
  return !isEmpty(stops)
    ? `${stops.length} (${stops
        .map((identifier) => {
          return identifier.value;
        })
        .join(' → ')})`
    : 'None (Direct service)';
};
