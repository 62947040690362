import { CarrierDictionaryModel, CarrierModel } from '@/lib/models/carrier/types';

export const getCarrierDetails = ({
  carrierIds,
  carriers,
}: {
  carrierIds: string[];
  carriers: CarrierDictionaryModel;
}): CarrierModel[] => {
  return carrierIds.map((carrierId) => carriers[carrierId]).filter(Boolean);
};
