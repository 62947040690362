import { startCase } from 'lodash';

import { AllPortAccessItemsInterface } from '@/types';
import { SeaportFeatureSetApiInterface } from '@/types/api-types';

const ALL_PORT_ACCESS_ITEMS: AllPortAccessItemsInterface[] = [
  {
    name: 'road',
  },
  {
    name: 'rail',
  },
  {
    name: 'inlandWaterway',
    icon: 'inland-waterway',
  },
];

/**
 * Handles converting a list of Port Features into an Access list
 * @param featureSet PortDataInterface
 * @returns
 */
export const formatPortAccessFeatureSet = (featureSet: SeaportFeatureSetApiInterface) => {
  return ALL_PORT_ACCESS_ITEMS.map((item) => {
    return {
      label: startCase(item.name),
      available: featureSet?.portAccess?.includes(item.name),
      icon: item.icon || item.name,
    };
  });
};
