import * as ToastPrimitive from '@radix-ui/react-toast';
import { AnimatePresence } from 'framer-motion';
import { useRecoilValue } from 'recoil';

import { Toast } from '@/components/ui/notifications/toast';
import { notificationsState } from '@/state/notifications';
import { tv } from '@/utils/styles';

const Notifications = () => {
  const notifications = useRecoilValue(notificationsState);

  const { wrapper, viewport } = styles();
  return (
    <div className={wrapper()}>
      <ToastPrimitive.Provider>
        {notifications.map((notification, i) => (
          <AnimatePresence key={notification.id}>
            <Toast
              key={notification.id}
              id={notification.id}
              reverseIndex={notifications.length - i}
              duration={notification.duration}
              variant={notification.variant}
              confirmButtonText={notification.confirmButtonText}
              icon={notification.icon}
            />
          </AnimatePresence>
        ))}
        <ToastPrimitive.ToastViewport className={viewport()} />
      </ToastPrimitive.Provider>
    </div>
  );
};

const styles = tv({
  slots: {
    wrapper: 'pointer-events-none fixed right-0 top-0 z-19 outline-0',
    viewport: 'relative right-0 top-0 m-2 flex max-w-[100vw] list-none flex-col-reverse gap-3 p-2 outline-0 md:m-4',
  },
});

export { Notifications };
