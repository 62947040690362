import { isEmpty } from 'lodash';
import { atom, selectorFamily } from 'recoil';

import { CarrierDictionaryModel, CarrierModel } from '@/lib/models/carrier/types';
import { getCarrierDetails } from '@/lib/models/carrier/utils/get-carrier-details';

// Carriers State
export const carriersState = atom<CarrierDictionaryModel>({
  key: 'carriersState',
  default: {},
});

export const getCarriersByIdsState = selectorFamily<CarrierModel[], string[]>({
  key: 'getCarriersByIdsState',
  get:
    (carrierIds) =>
    ({ get }) => {
      if (!carrierIds || isEmpty(carrierIds)) {
        return [];
      }

      const carrierDictionary = get(carriersState);

      return getCarrierDetails({ carrierIds, carriers: carrierDictionary });
    },
});

export const getCarrierByIdState = selectorFamily<CarrierModel, string>({
  key: 'getCarrierByIdState',
  get:
    (carrierId) =>
    ({ get }) => {
      const carrierDictionary = get(carriersState);
      return carrierDictionary[carrierId];
    },
});
