import { isString } from 'lodash';

import { AIRPORT, SEAPORT } from '@/lib/constants';
import { LocationInterface } from '@/types';

export const getLocationPortNodeId = (location: string | LocationInterface): string => {
  if (isString(location)) {
    return location;
  }
  if ('type' in location && (location.type === SEAPORT || location.type === AIRPORT)) {
    const id = location.identifier;
    return location.placeId || `${id?.type} ${id?.value}`;
  }

  return '';
};
