import { addWeeks } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import { startCase } from 'lodash';
import { atom, selector } from 'recoil';

import { getCarrierOptions } from '@/components/modules/schedules/filters/utils';
import { DateRangeValue } from '@/components/ui/inputs/advanced-date-picker/types';
import { dateRangeToSimpleDates } from '@/components/ui/inputs/advanced-date-picker/utils';
import { FILTER_AIRCRAFT_TYPE, FILTER_CARRIER_IDS, FILTER_DIRECT_SERVICES_ONLY, MODE_AIR } from '@/lib/constants';
import { scheduleSearchLimitReachedState } from '@/state/schedules';
import {
  FilterSelection,
  LocationInterface,
  OptionItem,
  ScheduleResultsState,
  StandaloneScheduleSearchState,
} from '@/types';
import { SegmentModeType } from '@/types/api-types';

import { getCarriersByIdsState } from './carriers';

export const DEFAULT_SCHEDULE_SEARCH_PARAMS = {
  origin: null,
  destination: null,
  mode: MODE_AIR,
  dateFrom: '2024-04-28T00:00:00.000Z',
  dateTo: '',
  carrier: 'all',
  aircraftType: 'all',
  serviceType: 'all',
} as const;

export const DEFAULT_DATE_RANGE = {
  type: 'departOn',
  date: addWeeks(startOfDay(new Date()), 1),
} as const;

export const standaloneSchedulesModeState = atom<SegmentModeType>({
  key: 'standaloneSchedulesModeState',
  default: MODE_AIR,
});

export const standaloneSchedulesOriginState = atom<LocationInterface | undefined>({
  key: 'standaloneSchedulesOriginState',
  default: undefined,
});

export const standaloneSchedulesDestinationState = atom<LocationInterface | undefined>({
  key: 'standaloneSchedulesDestinationState',
  default: undefined,
});

export const standaloneSchedulesDateRangeState = atom<DateRangeValue>({
  key: 'standaloneSchedulesDateRangeState',
  default: DEFAULT_DATE_RANGE,
});

export const standaloneSchedulesSearchState = atom<StandaloneScheduleSearchState>({
  key: 'standaloneSchedulesSearchState',
  default: {
    isSearching: false,
    hasSearched: false,
    error: undefined,
  },
});

export const standaloneSchedulesState = atom<ScheduleResultsState>({
  key: 'standaloneSchedulesState',
  default: {
    mode: MODE_AIR,
    schedules: [],
    alternativeRoutes: null,
  },
});

export const standaloneSchedulesCanSearch = selector<boolean>({
  key: 'standaloneSchedulesCanSearch',
  get: ({ get }) => {
    const destination = get(standaloneSchedulesDestinationState);
    const origin = get(standaloneSchedulesOriginState);
    const dateRange = get(standaloneSchedulesDateRangeState);
    const searchLimitReached = get(scheduleSearchLimitReachedState);
    const parsedDates = dateRangeToSimpleDates(dateRange);

    const hasValues = !!(origin && destination && (parsedDates.dateTo || parsedDates.dateFrom));
    return hasValues && !searchLimitReached;
  },
});

export const scheduleFiltersState = atom<FilterSelection>({
  key: 'scheduleFiltersState',
  default: {
    [FILTER_CARRIER_IDS]: [],
    [FILTER_AIRCRAFT_TYPE]: [],
    [FILTER_DIRECT_SERVICES_ONLY]: null,
  },
});

export const scheduleFiltersActiveCountState = selector<number>({
  key: 'scheduleFiltersActiveCountState',
  get: ({ get }) => {
    const filters = get(scheduleFiltersState);
    return Object.keys(filters).reduce((total, key) => {
      const value = filters[key as keyof typeof filters];

      if (Array.isArray(value)) {
        return total + value.length;
      }

      if (value !== undefined && value !== false && value) {
        return total + 1;
      }

      return total;
    }, 0);
  },
});

export const activeFilterOptionsState = selector({
  key: 'activeFilterOptionsState',
  get: ({ get }) => {
    const scheduleFilters = get(scheduleFiltersState);
    const carriersFromIds = get(getCarriersByIdsState(scheduleFilters[FILTER_CARRIER_IDS] || []));

    const carrierOptions = getCarrierOptions(carriersFromIds);

    const airCraftTypeOptions =
      scheduleFilters[FILTER_AIRCRAFT_TYPE]?.map((filter) => {
        return {
          label: startCase(filter),
          value: filter,
        } as OptionItem;
      }) || [];

    const directServicesOnly = scheduleFilters[FILTER_DIRECT_SERVICES_ONLY]
      ? [{ label: 'Direct services only', value: false }]
      : [];

    return {
      [FILTER_CARRIER_IDS]: carrierOptions,
      [FILTER_AIRCRAFT_TYPE]: airCraftTypeOptions,
      [FILTER_DIRECT_SERVICES_ONLY]: directServicesOnly,
    };
  },
});
