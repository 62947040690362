import posthog from 'posthog-js';

import { EventTrackingInterface, LocationInterface } from '@/types';
import { request } from '@/utils/api/request';
import { isProductionEnv } from '@/utils/helpers';

type Context = Record<string, unknown> | EventTrackingInterface;

// Is Production
const IS_PROD = isProductionEnv();
// Should track user? Default to Production only
export const SHOULD_TRACK_USER = IS_PROD;

const trackUser = {
  // Tracks an user event to analytics
  event(name: string, context?: Context): void {
    if (SHOULD_TRACK_USER) {
      // Post Hog
      posthog.capture(name, context);
    }
  },
  conversion({ action, category, label }: EventTrackingInterface) {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: action,
        event_category: category,
        event_label: label || '',
      });
    }
  },
  setUserIdentity(userId: string, context?: Context): void {
    if (SHOULD_TRACK_USER) {
      posthog.identify(userId, context);
    }
  },
  resetUserIdentity(): void {
    if (SHOULD_TRACK_USER) {
      posthog.reset();
    }
  },

  async usage(eventType: string, eventKey: string, data: LocationInterface[] | any) {
    if (eventType && SHOULD_TRACK_USER) {
      await request('/api/usage', {
        body: { eventType, eventKey, data },
      });
    }
  },
};

export { trackUser };
