import { ENVS } from '@/lib/constants';

interface Environment {
  env: {
    FLUENT_API_URL: string;
    GOOGLE_API_KEY: string;
  };
}

const Fluent: Environment = {
  env: {
    FLUENT_API_URL: (() => {
      if (
        // logic mirrors isPreviewEnv from helpers
        process.env.NEXT_PUBLIC_ENVIRONMENT === ENVS.PREVIEW &&
        process.env.NEXT_PUBLIC_ENVIRONMENT !== ENVS.DEVELOP_BRANCH
      ) {
        return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/api`;
      }
      return process.env.NEXT_PUBLIC_API_URL;
    })(),
    GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY || process.env.STORYBOOK_GOOGLE_API_KEY,
  },
};

export { Fluent };
