import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import { TV, tv as tvBase } from 'tailwind-variants';
import { TVConfig } from 'tailwind-variants/dist/config';

import tailwindConfig from '../../../tailwind.config';

/**
 * This allows us to use our custom theme values in the `twMerge` function.
 * Without this they will be overwritten by any values in that class group.
 * e.g. `text-baseLg` would be overwritten by `text-white` if it came after it.
 */
export const twMergeConfig = {
  // https://stackoverflow.com/questions/76355485/tailwind-merge-not-able-to-combine-color-and-typography-classes
  // https://github.com/epicweb-dev/epic-stack/issues/301#issuecomment-1631202868
  classGroups: {
    'font-size': Object.keys(tailwindConfig?.theme?.extend?.fontSize || {}).map((key) => `text-${key}`),
  },
};

const customTwMerge = extendTailwindMerge({ extend: twMergeConfig });

/**
 * A utility function that generates a class string based on the input class values.
 * Uses cslx which is a utility function that merges class names together and allows
 * for conditional class names
 *
 * @param inputs An array of class values to be merged into a single class string
 * @returns A string of merged class names
 */
export const cn = (...inputs: ClassValue[]) => {
  // Merge the input class values into a single class string using the `clsx` function.
  const classString = clsx(inputs);

  // Merge the class string with the Tailwind CSS classes using the `twMerge` function.
  return customTwMerge(classString);
};

/**
 * The tv function but with our custom twMergeConfig
 *
 * @param options - The options to pass to the tv function
 * @param customConfig - The custom config to pass to the tv function
 * @returns The class string
 */
export const tv: TV = (options, customConfig?: TVConfig) => {
  return tvBase(options, { ...customConfig, twMergeConfig });
};
