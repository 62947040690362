/**
 * Each log event sends to Rollbar
 */
import React from 'react';
import Rollbar from 'rollbar';

import { ENVS } from '@/lib/constants';

const ENV = process.env.NEXT_PUBLIC_ENVIRONMENT || ENVS.LOCAL;

export type MessageContext = Record<string, unknown> | React.ErrorInfo;

const isServerSide = typeof window === 'undefined';

export const ROLLBAR_CONFIG = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: ENV !== ENVS.LOCAL,
  payload: {
    environment: ENV,
    client: {
      javascript: {
        code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
};

export const ROLLBAR_SERVER_CONFIG = {
  ...ROLLBAR_CONFIG,
  accessToken: process.env.ROLLBAR_SERVER_TOKEN,
};

const initLogEvent = () => {
  const rollbar = new Rollbar(!isServerSide ? ROLLBAR_CONFIG : ROLLBAR_SERVER_CONFIG);

  return {
    debug(message: string, messageContext: MessageContext): void {
      // Rollbar won't log errors locally so
      // can sit outside the conditional
      rollbar.debug(message, messageContext);
    },
    info(message: string, messageContext: MessageContext): void {
      if (ENV === ENVS.LOCAL) {
        console.info(message, messageContext);
      }
      rollbar.info(message, messageContext);
    },
    warn(message: string, messageContext: MessageContext): void {
      rollbar.warning(message, messageContext);
    },
    error(message: string | Error, messageContext: MessageContext): void {
      if (ENV === ENVS.LOCAL) {
        console.error(message, messageContext);
      }
      rollbar.error(message, messageContext);
    },
  };
};

const logEvent = initLogEvent();

export { logEvent };
