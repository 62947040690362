import { head } from 'lodash';

import { NearbyNodeInterface, PortDetails } from '@/types';
import { PortNodeApiInterface } from '@/types/api-types';
import { getPreferredIdentifier } from '@/utils/helpers';

/**
 * Formats the Port Description used in the Page description and SEO description
 * @returns Melbourne is a seaport near {Melbourne} in {Australia (AU)}. It is {18km} away from the nearest {airport} ({Melbourne International Airport}). The offical UN/Locode for this port is {AUMEL}.
 */
export const formatPortDescription = (
  port: PortNodeApiInterface,
  countryName: string,
  nearbyCities: NearbyNodeInterface[] | null | undefined,
  nearbyPorts: NearbyNodeInterface[] | null | undefined,
): string => {
  const prefferedIdentifier = getPreferredIdentifier(port.identifiers);
  // Get closest City
  const nearestCity = head(nearbyCities)?.name;
  const hasNearbyCity = !!nearestCity;
  // Get nearby city name and ensure its not the same as the nearest city name (aka Singapore)
  const nearbyCityNameString = hasNearbyCity && countryName !== nearestCity ? ` near ${nearestCity}` : ``;
  // Get country name
  const countryNameString = ` in ${countryName} (${port.countryCode})`;
  // Filter down to alternative ports, ie. if this Port is Seaport, only get Airports
  const altPorts = nearbyPorts?.filter((p) => p.type !== port.type);
  const firstPort = head(altPorts);
  // Build Nearby Port string
  const nearestPortStr = firstPort
    ? ` It is ${Math.floor(firstPort.distanceKm)}km away from the nearest ${firstPort.type} (${firstPort.name}).`
    : '';
  // Build Code string
  const portCodeString = prefferedIdentifier?.value
    ? ` The offical ${prefferedIdentifier.type.toUpperCase()} for this ${port.type} is ${prefferedIdentifier.value}.`
    : '';

  // Assemble final content string
  return `${port.name} is a ${port.type}${nearbyCityNameString}${countryNameString}.${nearestPortStr}${portCodeString}`;
};

/**
 * Generates the port page title used for SEO
 */
export const formatPortTitle = (port: PortDetails) => {
  return `${port.name} (${port.portId})`;
};
