/**
 * Formats port indentifiers into a string
 *
 * e.g iata MEL|icao YMML|locode AUMEL
 *
 * @param identifers an array of IdentifierApiInterface
 *
 * @returns a string of formatted port indentifiers
 */

import { IdentifierApiInterface } from '@/types/api-types';

export const formatPortIndentifers = (identifers: IdentifierApiInterface[]) =>
  identifers.map((item) => `${item.type} ${item.value}`).join('|');
