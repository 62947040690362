import { SITE_NAME } from '@/lib/constants';
import { SeoMetaTagType } from '@/types';

import { injectCustomSeoData } from './utils/inject-custom-seo-data';

interface MetaDataInterface {
  readonly title: string;
  readonly description: string;
  readonly others?: SeoMetaTagType[];
}

const createMetaTagsWithCustomTitleAndDescription = ({
  title,
  description,
  others,
}: MetaDataInterface): SeoMetaTagType[] => {
  const customTitle = `${title} - ${SITE_NAME}`;
  const customSeoData = [
    { content: customTitle, attributes: null, tag: 'title' },
    {
      tag: 'meta',
      attributes: { property: 'og:title', content: customTitle },
      content: null,
    },
    {
      tag: 'meta',
      attributes: { name: 'twitter:title', content: customTitle },
      content: null,
    },
    {
      tag: 'meta',
      attributes: {
        name: 'description',
        content: description,
      },
      content: null,
    },
    {
      tag: 'meta',
      attributes: {
        property: 'og:description',
        content: description,
      },
      content: null,
    },
    {
      tag: 'meta',
      attributes: {
        property: 'twitter:description',
        content: description,
      },
      content: null,
    },
  ] as SeoMetaTagType[];

  const seoDataWithInjectedCustomSeoData = others?.length
    ? // First check if 'others' exists
      injectCustomSeoData(customSeoData, others)
    : // Otherwise just return the custom data
      customSeoData;

  return [...(seoDataWithInjectedCustomSeoData || [])];
};

export { createMetaTagsWithCustomTitleAndDescription };
