import { DialogTriggerProps, Trigger } from '@radix-ui/react-dialog';
import React from 'react';

import { cn } from '@/utils/styles';

const DialogTrigger = ({ children, className, ...rest }: DialogTriggerProps) => {
  return (
    <Trigger className={cn('block text-left', className)} {...rest}>
      {children}
    </Trigger>
  );
};

export { DialogTrigger };
