/**
 * Responsive Breakpoints
 * https://styled-system.com/responsive-styles
 *
 */

export const BREAKPOINTS_AS_NUMBERS = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1440,
  '3XL': 1800,
};

type Breakpoints = string[] & {
  [key: string]: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
};

/**
 * We need the array of breakpoints as the main backbone of the Styled System
 * responsive functionality but avoid using the array where the object
 * notation version will work
 * e.g. <Box width={{ _: 1, lg: 6 / 12, xl: 4 / 12 }} />
 */

const breakpoints = [
  `${BREAKPOINTS_AS_NUMBERS.SM}px`, // 0
  `${BREAKPOINTS_AS_NUMBERS.MD}px`, // 1
  `${BREAKPOINTS_AS_NUMBERS.LG}px`, // 2
  `${BREAKPOINTS_AS_NUMBERS.XL}px`, // 3
  `${BREAKPOINTS_AS_NUMBERS['2XL']}px`, // 4
  `${BREAKPOINTS_AS_NUMBERS['3XL']}px`, // 5
] as Breakpoints;

/**
 * Breakpoint aliases
 * https://styled-system.com/responsive-styles/#using-objects
 */
/* eslint-disable prefer-destructuring */
breakpoints.sm = breakpoints[0]; // 640px
breakpoints.md = breakpoints[1]; // 768px
breakpoints.lg = breakpoints[2]; // 1024px
breakpoints.xl = breakpoints[3]; // 1280px
breakpoints.xxl = breakpoints[4]; // 1440px
breakpoints.xxxl = breakpoints[5]; // 1800px

export { breakpoints };
