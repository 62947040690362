import * as yup from 'yup';

import { CargoTypeSchemaType } from '@/types';

export const QUOTE_FORM_FIELDS = {
  CARGO_TYPE: 'cargoType',
  QUANTITY: 'quantity',
  CONTAINER_SIZE: 'containerSize',
  CARGO_DESCRIPTION: 'cargoDescription',
  TOTAL_VOLUME: 'totalVolume',
  UNIT_VOLUME: 'unitVolume',
  TOTAL_WEIGHT: 'totalWeight',
  UNIT_WEIGHT: 'unitWeight',
  NUMBER_OF_ITEMS: 'numberOfItems',
  ITEM_CATEGORY: 'itemCategory',
  SPECIAL_HANDLING: 'specialHandling',
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  READY_FOR_PICKUP: 'readyForPickup',
  SHIPMENT_DETAILS: 'shipmentDetails',
  NAME: 'name',
  BUSINESS_NAME: 'businessName',
  EMAIL: 'email',
  PHONE: 'phone',
  COUNTRY: 'country',
  SENDS_CARGO_REGULARLY: 'sendsCargoRegularly',
} as const;

export const QUOTE_FORM_FIELDS_CARGO_DETAILS = [
  QUOTE_FORM_FIELDS.QUANTITY,
  QUOTE_FORM_FIELDS.CONTAINER_SIZE,
  QUOTE_FORM_FIELDS.CARGO_DESCRIPTION,
  QUOTE_FORM_FIELDS.TOTAL_VOLUME,
  QUOTE_FORM_FIELDS.UNIT_VOLUME,
  QUOTE_FORM_FIELDS.TOTAL_WEIGHT,
  QUOTE_FORM_FIELDS.UNIT_WEIGHT,
  QUOTE_FORM_FIELDS.NUMBER_OF_ITEMS,
  QUOTE_FORM_FIELDS.ITEM_CATEGORY,
];

export const CONTAINER_ITEM_CATEGORY = [
  'Construction equipment',
  'Mining equipment',
  'Machinery',
  'Vehicles / cars / motorbikes',
  'Watercraft / Boats etc',
  'Aerospace ',
  'Household Goods',
  'Entire house contents',
  'Dry bulk cargo',
  'Crude oil and derivatives',
  'Liquid bulk cargo',
  'Live animals',
  'Foodstuff',
  'Chemicals or toxic products',
  'Loose cargo / boxes / pallets',
  'Other',
];

export const CARGO_TYPES = {
  FCL: 'FCL',
  LCL: 'LCL',
  AIR: 'Air',
  PERSONAL: 'Personal',
  OTHER: 'Other',
};

export const SPECIAL_HANDLING = {
  YES: 'yes',
  NO: 'no',
};

export const QUOTES_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  PUBLISHED: 'published',
  REJECTED: 'rejected',
  FORWARDED: 'forwarded',
  EXPIRED: 'expired',
  CLOSED: 'closed',
};

export const validationSchema = yup
  .object({
    [QUOTE_FORM_FIELDS.CARGO_TYPE]: yup.string().required('Select a cargo option'),
    [QUOTE_FORM_FIELDS.QUANTITY]: yup
      .number()
      .when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
        is: (cargoType: string) => cargoType === CARGO_TYPES.FCL,
        then: yup.number().min(1, 'Cannot be less than 1').required('Enter a quantity').nullable(),
      })
      .nullable(),
    [QUOTE_FORM_FIELDS.CONTAINER_SIZE]: yup.string().when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
      is: (cargoType: string) => cargoType === CARGO_TYPES.FCL,
      then: yup.string().required('Select a container size'),
    }),
    [QUOTE_FORM_FIELDS.TOTAL_VOLUME]: yup
      .number()
      .when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
        is: (cargoType: string) => cargoType === CARGO_TYPES.LCL,
        then: yup
          .number()
          .min(1, 'Cannot be less than 1')
          .required('Enter a total volume')
          .typeError('Enter a valid number for the total volume'),
      })
      .required('Enter a total volume')
      .typeError('Enter a valid number for the total volume'),
    [QUOTE_FORM_FIELDS.UNIT_VOLUME]: yup.string().when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
      is: (cargoType: string) => cargoType === CARGO_TYPES.LCL,
      then: yup.string().required('Select a unit of volume'),
    }),
    [QUOTE_FORM_FIELDS.TOTAL_WEIGHT]: yup
      .number()
      .when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
        is: (cargoType: string) => cargoType === CARGO_TYPES.AIR || cargoType === CARGO_TYPES.PERSONAL,
        then: yup
          .number()
          .min(1, 'Cannot be less than 1')
          .required('Enter a total weight')
          .typeError('Enter a valid number for the total weight'),
      })
      .required('Enter a total weight')
      .typeError('Enter a valid number for the total weight'),
    [QUOTE_FORM_FIELDS.UNIT_WEIGHT]: yup.string().when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
      is: (cargoType: string) => cargoType === CARGO_TYPES.AIR || cargoType === CARGO_TYPES.PERSONAL,
      then: yup.string().required('Select a unit of weight'),
    }),
    [QUOTE_FORM_FIELDS.NUMBER_OF_ITEMS]: yup.number().when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
      is: (cargoType: string) => cargoType === CARGO_TYPES.PERSONAL,
      then: yup.number().min(1, 'Cannot be less than 1').required('Enter a quantity'),
    }),
    [QUOTE_FORM_FIELDS.ITEM_CATEGORY]: yup.string().when(QUOTE_FORM_FIELDS.CARGO_TYPE, {
      is: (cargoType: string) => cargoType === CARGO_TYPES.OTHER,
      then: yup.string().required('Select an item category'),
    }),
    [QUOTE_FORM_FIELDS.CARGO_DESCRIPTION]: yup.string(),
    [QUOTE_FORM_FIELDS.SPECIAL_HANDLING]: yup.string().required('Any special handling required?'),
    [QUOTE_FORM_FIELDS.ORIGIN]: yup.string().required('Search for and select a pickup location'),
    [QUOTE_FORM_FIELDS.DESTINATION]: yup.string().required('Search for and select a drop-off location'),
    [QUOTE_FORM_FIELDS.READY_FOR_PICKUP]: yup.string().required('Select a pickup date'),
    [QUOTE_FORM_FIELDS.SHIPMENT_DETAILS]: yup.string(),
    [QUOTE_FORM_FIELDS.NAME]: yup.string().required('Provide a contact name'),
    [QUOTE_FORM_FIELDS.BUSINESS_NAME]: yup.string(),
    [QUOTE_FORM_FIELDS.EMAIL]: yup.string().email('Invalid email address').required('Provide an email address'),
    [QUOTE_FORM_FIELDS.PHONE]: yup
      .string()
      .transform((value) => (value === '' ? null : value))
      .matches(/^(\+?\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\s?$/, 'Invalid phone number')
      .nullable(),
    [QUOTE_FORM_FIELDS.COUNTRY]: yup.string().required('Select a country'),
    [QUOTE_FORM_FIELDS.SENDS_CARGO_REGULARLY]: yup.string().required('Select a sending frequency'),
  })
  .required();

/**
 * We need to define the schema for each cargo type so we can validate
 * the form based on the selected cargo type by resetting the fields
 * that are not required for the selected cargo type
 */
export const cargoTypeSchemas: Record<string, CargoTypeSchemaType> = {
  [CARGO_TYPES.FCL]: {
    [QUOTE_FORM_FIELDS.QUANTITY]: true,
    [QUOTE_FORM_FIELDS.CONTAINER_SIZE]: true,
    [QUOTE_FORM_FIELDS.CARGO_DESCRIPTION]: true,
  },
  [CARGO_TYPES.LCL]: {
    [QUOTE_FORM_FIELDS.TOTAL_VOLUME]: true,
    [QUOTE_FORM_FIELDS.UNIT_VOLUME]: true,
    [QUOTE_FORM_FIELDS.CARGO_DESCRIPTION]: true,
  },
  [CARGO_TYPES.AIR]: {
    [QUOTE_FORM_FIELDS.TOTAL_WEIGHT]: true,
    [QUOTE_FORM_FIELDS.UNIT_WEIGHT]: true,
    [QUOTE_FORM_FIELDS.CARGO_DESCRIPTION]: true,
  },
  [CARGO_TYPES.PERSONAL]: {
    [QUOTE_FORM_FIELDS.TOTAL_WEIGHT]: true,
    [QUOTE_FORM_FIELDS.UNIT_WEIGHT]: true,
    [QUOTE_FORM_FIELDS.NUMBER_OF_ITEMS]: true,
    [QUOTE_FORM_FIELDS.CARGO_DESCRIPTION]: true,
  },
  [CARGO_TYPES.OTHER]: {
    [QUOTE_FORM_FIELDS.ITEM_CATEGORY]: true,
    [QUOTE_FORM_FIELDS.CARGO_DESCRIPTION]: true,
  },
};
