import * as DialogPrimitive from '@radix-ui/react-dialog';

import { tv } from '@/utils/styles';

import { DialogContent, DialogContentProps } from './content';
import { DialogTrigger } from './trigger';

const Dialog = DialogPrimitive.Root;

export const dialogStyles = tv({
  slots: {
    description: 'mb-0 pt-2 text-baseSm text-grey-600 [&_p]:last:mb-0',
    logo: 'mb-6 w-[132px] [&_svg]:w-full',
  },
});

export type { DialogContentProps };
export { Dialog, DialogContent, DialogTrigger };
