import { atom } from 'recoil';

export interface AlertDialogStateInterface {
  title?: string;
  description?: string;
  isOpen?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  hideActions?: boolean;
  leaveOpenOnConfirm?: boolean;
}

export const alertDialogState = atom<AlertDialogStateInterface>({
  key: 'alertDialogState',
  default: {
    title: undefined,
    description: undefined,
    isOpen: false,
    confirmButtonText: undefined,
    cancelButtonText: undefined,
    onConfirm: () => {},
    onCancel: () => {},
    isLoading: false,
    hideActions: false,
    leaveOpenOnConfirm: false,
  },
});

// The container ref for the the alert dialog portal
export const alertDialogPortalState = atom<HTMLElement | HTMLDivElement | null>({
  key: 'alertDialogPortalState',
  default: null,
});
