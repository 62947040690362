import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { DateRangeValue, DateTimeType } from '@/components/ui/inputs/advanced-date-picker/types';
import { DATE_FORMAT, FULL_DATETIME_FORMAT } from '@/lib/constants';
import { DEFAULT_DATE_RANGE } from '@/state/standalone-schedules';

export type SimpleDateRange = {
  dateFrom?: Date;
  dateTo?: Date;
};

const formatDate = (date: Date, shouldIncludeHours: boolean) => {
  const hours = date.getHours();
  if (hours && shouldIncludeHours) {
    return format(date, FULL_DATETIME_FORMAT);
  }

  return format(date, DATE_FORMAT);
};

export const getDateLabel = (
  { date, dateRange }: { date?: Date; dateRange?: DateRange },
  shouldIncludeHours = true,
): string => {
  if (date) return formatDate(date!, shouldIncludeHours);

  const fromDate = dateRange?.from ? formatDate(dateRange.from, shouldIncludeHours) : null;
  const toDate = dateRange?.to ? formatDate(dateRange.to, shouldIncludeHours) : null;

  return [fromDate, toDate].filter(Boolean).join(' - ');
};

export const dateRangeToSimpleDates = (value: DateRangeValue): SimpleDateRange => {
  if (value.type === 'range') {
    const dateFrom = value.dateRange?.from;
    dateFrom?.setHours(value.departHour || 0);
    const dateTo = value.dateRange?.to;
    dateTo?.setHours(value.arriveHour || 0);

    return {
      dateFrom,
      dateTo,
    };
  }

  if (value.type === 'departOn') {
    const dateFrom = value.date;
    dateFrom?.setHours(value.departHour || 0);

    return {
      dateFrom,
    };
  }

  const dateTo = value.date;
  dateTo?.setHours(value.arriveHour || 0);
  return {
    dateTo,
  };
};

export const simpleDatesToDateRange = (
  { dateTo, dateFrom }: SimpleDateRange,
  dateType?: DateTimeType,
): DateRangeValue => {
  const departDate = dateFrom ? new Date(dateFrom) : null;
  const arriveDate = dateTo ? new Date(dateTo) : null;

  if (departDate && arriveDate) {
    return {
      type: 'range',
      dateRange: {
        from: departDate,
        to: arriveDate,
      },
      departHour: departDate.getHours(),
      arriveHour: arriveDate.getHours(),
    };
  }

  if (departDate) {
    return {
      type: dateType || 'departOn',
      date: departDate,
      departHour: departDate.getHours(),
    };
  }

  if (arriveDate) {
    return {
      type: dateType || 'arriveBy',
      date: arriveDate,
      arriveHour: arriveDate.getHours(),
    };
  }

  return {
    type: dateType || 'departOn',
    date: DEFAULT_DATE_RANGE.date,
  };
};
