import { API_ERROR } from '@/lib/constants';

/**
 * In certain circumstances we want to suppress error logging to Rollbar
 * @param json - json response object
 * @returns boolean
 */
export const shouldLogError = (json: any): boolean => {
  if (json.error) {
    // If no port was found using the ports API we want to suppress the error.
    // Note: these will still be picked up by Logflare or Posthog
    if (json.error === API_ERROR.PORT_NOT_FOUND || json.error === API_ERROR.QUOTA_EXCEEDED) return false;
    // if there is any other error return true
    return true;
  }
  return false;
};

/**
 * In certain circumstances we want to send an info log to Rollbar
 * @param json - json response object
 * @returns boolean
 */
export const shouldLogInfo = (json: any): boolean => {
  if (json.error && json.error === API_ERROR.QUOTA_EXCEEDED) return true;
  return false;
};
