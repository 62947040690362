/**
 * This context handles Posthog Analytics tracking
 * Is intended for Client Side use ONLY
 */
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { useEffect } from 'react';

// Is Production
const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;

interface Props {
  children: React.ReactNode;
}

if (!POSTHOG_KEY) console.error('Missing NEXT_PUBLIC_POSTHOG_KEY in client/main/.env');

export const PostHogContextProvider = (props: Props) => {
  const router = useRouter();

  // POSTHOG Setup
  // Check that PostHog is client-side (used to handle Next.js SSR)
  if (typeof window !== 'undefined' && POSTHOG_KEY) {
    posthog.init(POSTHOG_KEY, {
      api_host: 'https://app.posthog.com',
      autocapture: false,
      opt_in_site_apps: true,
      // Enable debug mode in development
      // loaded: (ph) => {
      //   if (!IS_PROD) ph.debug();
      // },
    });
  }

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <PostHogProvider client={posthog} {...props} />;
};
