import { CARGO_TYPES } from '@/components/forms/quote-form/constants';
import { QuotesInterface } from '@/types';

/**
 * Format the cargo details for display
 * @param cargoDetails - The cargo details
 * @param cargoType - The cargo type
 * @returns The formatted cargo details
 */
export const formatCargoDetails = (
  cargoDetails: QuotesInterface['cargoDetails'],
  cargoType: QuotesInterface['cargoType'],
): string => {
  if (cargoType === CARGO_TYPES.FCL) {
    return `${cargoDetails.quantity} x ${cargoDetails.containerSize}`;
  }

  if (cargoType === CARGO_TYPES.LCL) {
    return `${cargoDetails.totalVolume} ${cargoDetails.unitVolume}`;
  }

  if (cargoType === CARGO_TYPES.AIR) {
    return `${cargoDetails.totalWeight} ${cargoDetails.unitWeight}`;
  }

  if (cargoType === CARGO_TYPES.PERSONAL) {
    return `${cargoDetails.numberOfItems} x ${cargoDetails.totalWeight}${cargoDetails.unitWeight}`;
  }

  if (cargoType === CARGO_TYPES.OTHER) {
    return cargoDetails.itemCategory || '';
  }

  return '';
};
