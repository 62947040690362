import tailwindContainerQueries from '@tailwindcss/container-queries';
import tailwindTypographyPlugin from '@tailwindcss/typography';
import { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';
import tailwindAnimate from 'tailwindcss-animate';

import { breakpoints } from './src/theme/constants/breakpoints';
import { colors } from './src/theme/constants/colors';
import { fontSizes } from './src/theme/constants/font-sizes';
import { fontWeights } from './src/theme/constants/font-weights';
import { lineHeights } from './src/theme/constants/line-heights';
import { radii } from './src/theme/constants/radii';
import { transitions } from './src/theme/constants/transitions';
import { zIndices } from './src/theme/constants/z-indices';

export const CASUAL_FONT_CSS_VARIABLE = '--casual-font';

// pluck charts and maps colors from theme since their structure is not supported by tw
const { charts, maps, ...themeColors } = colors;

const additionalClasses = plugin(({ addUtilities, addVariant }) => {
  addUtilities({
    '.backface-hidden': {
      'backface-visibility': 'hidden',
    },
  });
  addVariant('hocus', ['&:hover', '&:focus']);
});

const writingMode = plugin(({ addUtilities }) => {
  const writingModeUtilities = {
    '.horizontal-tb': {
      writingMode: 'horizontal-tb',
    },
    '.vertical-rl': {
      writingMode: 'vertical-rl',
    },
    '.vertical-lr': {
      writingMode: 'vertical-lr',
    },
  };
  addUtilities(writingModeUtilities);
});

const config: Config = {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    '!./node_modules',
  ],
  theme: {
    colors: themeColors,

    extend: {
      boxShadow: {
        outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
        soft: '2px 2px 6px rgba(0, 0, 0, 0.05)',
        softer: '2px 2px 8px rgba(0, 0, 0, 0.04)',
        card: '2px 4px 10px rgba(0, 0, 0, 0.06)',
        solid: '0px 0px 0px 4px',
      },
      fontFamily: {
        // The Tailwind default sans font stack shows what appears to be Verdana
        // as the 'system-ui' font in snapshots so we have to remove it
        // from the stack.
        sans: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          '"Noto Sans"',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
        casual: [`var(${CASUAL_FONT_CSS_VARIABLE})`],
      },
      keyframes: {
        'dialog-overlay-show': {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
        'dialog-content-show': {
          '0%': { opacity: '0', transform: 'translate(-50%, -44%) scale(.96)' },
          '100%': { opacity: '1', transform: 'translate(-50%, -50%) scale(1)' },
        },
        'bounce-x': {
          '0%, 100%': { transform: 'translateX(0)' },
          '50%': { transform: 'translateX(5px)' },
        },
        'bounce-y': {
          '0%, 100%': { transform: 'translateY(0)' },
          '50%': { transform: 'translateY(5px)' },
        },
        'light-blue-pulse': {
          '0%': { boxShadow: '0 0 0 0 rgba(7, 149, 255, .8)' },
          '70%': { boxShadow: '0 0 0 10px rgba(7, 149, 255, 0)' },
          '100%': { boxShadow: '0 0 0 0 rgba(7, 149, 255, 0)' },
        },
        'dark-blue-pulse': {
          '0%': { boxShadow: '0 0 0 0 rgba(31, 80, 125, .8)' },
          '70%': { boxShadow: '0 0 0 10px rgba(31, 80, 125, 0)' },
          '100%': { boxShadow: '0 0 0 0 rgba(31, 80, 125, 0)' },
        },
        'rotate-left': {
          '0%': { transform: 'translateY(0px) rotate(0)' },
          '50%': { transform: 'translateY(8px) rotate(0)' },
          '100%': { transform: 'translateY(8px) rotate(45deg)' },
        },
        'rotate-right': {
          '0%': { transform: 'translateY(0px) rotate(0)' },
          '50%': { transform: 'translateY(-8px) rotate(0)' },
          '100%': { transform: 'translateY(-8px) rotate(-45deg)' },
        },
        'enter-from-left': {
          from: { opacity: '0', transform: 'translateX(-200px)' },
          to: { opacity: '1', transform: 'translateX(0)' },
        },
        'enter-from-right': {
          from: { opacity: '0', transform: 'translateX(200px)' },
          to: { opacity: '1', transform: 'translateX(0)' },
        },
        'exit-to-left': {
          from: { opacity: '1', transform: 'translateX(0)' },
          to: { opacity: '0', transform: 'translateX(-200px)' },
        },
        'exit-to-right': {
          from: { opacity: '1', transform: 'translateX(0)' },
          to: { opacity: '0', transform: 'translateX(200px)' },
        },
        'fade-in': {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        'fade-out': {
          from: { opacity: '1' },
          to: { opacity: '0' },
        },
        'scale-in': {
          from: { opacity: '0', transform: 'rotateX(-30deg) scale(0.9)' },
          to: { opacity: '1', transform: 'rotateX(0deg) scale(1)' },
        },
        'scale-out': {
          from: { opacity: '1', transform: 'rotateX(0deg) scale(1)' },
          to: { opacity: '0', transform: 'rotateX(-10deg) scale(0.95)' },
        },
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        dash: {
          '0%': {
            'stroke-dasharray': '1, 150',
            'stroke-dashoffset': '0',
          },
          '50%': {
            'stroke-dasharray': '90, 150',
            'stroke-dashoffset': '-35',
          },
          '100%': {
            'stroke-dasharray': '90, 150',
            'stroke-dashoffset': '-124',
          },
        },
        wave: {
          '0%': { transform: 'rotate(0.0deg)' },
          '15%': { transform: 'rotate(14.0deg)' },
          '30%': { transform: 'rotate(-8.0deg)' },
          '40%': { transform: 'rotate(14.0deg)' },
          '50%': { transform: 'rotate(-4.0deg)' },
          '60%': { transform: 'rotate(10.0deg)' },
          '70%': { transform: 'rotate(0.0deg)' },
          '100%': { transform: 'rotate(0.0deg)' },
        },
        blink: {
          '0%, 80%, 100% ': { opacity: '0' },
          '40%': { opacity: '1' },
        },
        notification: {
          '0%': {
            transform: 'scale(1)',
            opacity: '0.8',
          },
          '100%': {
            transform: 'scale(2)',
            opacity: '0',
          },
        },
        marquee: {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(calc(-100% - var(--gap)))' },
        },
        'marquee-vertical': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(calc(-100% - var(--gap)))' },
        },
      },
      animation: {
        'dialog-overlay-show': 'dialog-overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1)',
        'dialog-content-show': 'dialog-content-show 800ms cubic-bezier(0.16, 1, 0.3, 1) forwards',
        'bounce-x': 'bounce-x 1s infinite',
        'bounce-y': 'bounce-y 1s infinite',
        'light-blue-pulse': 'light-blue-pulse 2.3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite',
        'dark-blue-pulse': 'dark-blue-pulse 2.3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite',
        dash: 'dash 1.5s ease-in-out infinite',
        'rotate-left': 'rotate-left 0.5s cubic-bezier(0.5, 0.2, 0.2, 1.01) linear',
        'rotate-right': 'rotate-right 0.5s cubic-bezier(0.5, 0.2, 0.2, 1.01) linear',
        'enter-from-left': 'enter-from-left 0.25s ease',
        'enter-from-right': 'enter-from-right 0.25s ease',
        'exit-to-left': 'exit-to-left 0.25s ease',
        'exit-to-right': 'exit-to-right 0.25s ease',
        'fade-in': 'fade-in 0.2s ease',
        'fade-out': 'fade-out 0.2s ease',
        'scale-in': 'scale-in 0.2s ease',
        'scale-out': 'scale-out 0.2s ease',
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        wave: 'wave 1.5s forwards',
        'blink-1': '1s ease-in-out 0ms infinite normal none running blink',
        'blink-2': '1s ease-in-out 160ms infinite normal none running blink',
        'blink-3': '1s ease-in-out 320ms infinite normal none running blink',
        notification: 'notification 1.7s ease infinite',
        marquee: 'marquee var(--duration) linear infinite',
        'marquee-vertical': 'marquee-vertical var(--duration) linear infinite',
      },
      borderRadius: {
        ...radii,
      },
      fontSize: {
        ...fontSizes,
      },
      fontWeight: {
        ...fontWeights,
      },
      lineHeight: {
        ...lineHeights,
      },
      transitionDuration: {
        '400': '400ms',
        '2000': '2000ms',
      },
      transitionTimingFunction: {
        ...transitions,
      },
      screens: {
        '2xl': breakpoints.xxl,
        '3xl': breakpoints.xxxl,
      },
      spacing: {
        // @TODO: Don't use these, use the default Tailwind spacing scale instead. Tailwind scale in comment
        xs: '0.25rem', // 1
        sm: '0.5rem', // 2
        base: '1rem', // 4
        md: '1.5rem', // 6
        lg: '2rem', // 8
        xl: '3rem', // 12
        xxl: '4rem', // 16
        '2xl': '4rem', // 16
        xxxl: '6rem', // 24
        '3xl': '6rem', // 24
        xxxxl: '8rem', // 32
        '4xl': '8rem', // 32
        none: '0',
      },
      zIndex: {
        ...zIndices.reduce((acc, curr, i) => {
          return { ...acc, [i]: curr };
        }, {}),
      },
    },
  },
  plugins: [
    tailwindTypographyPlugin({
      className: 'rich-text',
    }),
    tailwindAnimate,
    additionalClasses,
    writingMode,
    tailwindContainerQueries,
    plugin(({ matchUtilities }) => {
      matchUtilities({
        perspective: (value) => ({
          perspective: value,
        }),
      });
    }),
  ],
};

export default config;
