export const hexToRgba = (hex: string): [number, number, number, number] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        result[4] ? parseInt(result[4], 16) : 255,
      ]
    : [0, 0, 0, 255];
};

/**
 * Converts a HEX color string to a normalized RGB array
 *
 * @param hex - The HEX color string (e.g., "#003968")
 * @returns An array of normalized RGB values between 0 and 1
 */
export const hexToNormalizedRgb = (hex: string): [number, number, number] => {
  const modifiedHex = hex.startsWith('#') ? hex.substring(1) : hex;

  if (modifiedHex.length !== 6) {
    throw new Error('Invalid HEX color format. Expected 6 characters.');
  }

  const r = parseInt(modifiedHex.substring(0, 2), 16) / 255;
  const g = parseInt(modifiedHex.substring(2, 4), 16) / 255;
  const b = parseInt(modifiedHex.substring(4, 6), 16) / 255;

  return [r, g, b];
};
