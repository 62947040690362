import { DefaultTheme } from 'styled-components';

import { selectBoxTheme } from '@/components/inputs/select-box/theme';
import { breakpoints } from '@/theme/constants/breakpoints';
import { colors } from '@/theme/constants/colors';
import { fontSizes } from '@/theme/constants/font-sizes';
import { fontWeights } from '@/theme/constants/font-weights';
import { lineHeights } from '@/theme/constants/line-heights';
import { radii } from '@/theme/constants/radii';
import { shadows } from '@/theme/constants/shadows';
import { space } from '@/theme/constants/space';
import { transitions } from '@/theme/constants/transitions';
import { zIndices } from '@/theme/constants/z-indices';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const baseTheme: DefaultTheme = {
  fonts: {
    body: '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol',
    mono: 'Menlo, Monaco,"Cascadia Mono","Segoe UI Mono","Roboto Mono","Oxygen Mono","Ubuntu Monospace","Source Code Pro","Fira Mono","Droid Sans Mono","Courier New",monospace',
  },
  lineHeights,
  fontWeights,
  fontSizes,
  breakpoints,
  colors,
  shadows,
  space,
  radii,
  transitions,
  forms: {
    ...selectBoxTheme,
  },
  zIndices,
};

export { baseTheme };
