/**
 * Uses: https://www.radix-ui.com/docs/primitives/components/alert-dialog
 */

import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button } from '@/components/button';
import { alertDialogPortalState, alertDialogState, AlertDialogStateInterface } from '@/state/alert';
import { tv } from '@/utils/styles';

export interface AlertDialogProps extends AlertDialogPrimitive.AlertDialogProps, AlertDialogStateInterface {}

const AlertDialog = ({ ...props }: AlertDialogProps) => {
  const [alertDialog, setAlertDialog] = useRecoilState(alertDialogState);
  const alertDialogPortal = useRecoilValue(alertDialogPortalState);

  const {
    title,
    description,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel,
    isLoading,
    hideActions,
    isOpen,
    leaveOpenOnConfirm = false,
  } = alertDialog;

  const handleConfirm = () => {
    if (!leaveOpenOnConfirm) {
      setAlertDialog({ isOpen: false });
    }

    if (onConfirm) {
      onConfirm();
    }
  };

  const handleClose = () => {
    setAlertDialog({ isOpen: false });

    if (onCancel) {
      onCancel();
    }
  };

  const { overlay, content, titleStyles, descriptionStyles, actions, cancelButton } = styles();

  return (
    <AlertDialogPrimitive.Root open={isOpen} {...props}>
      <AlertDialogPrimitive.Portal container={alertDialogPortal}>
        <AlertDialogPrimitive.Overlay className={overlay()} />
        <AlertDialogPrimitive.Content className={content()}>
          {title && <AlertDialogPrimitive.Title className={titleStyles()}>{title}</AlertDialogPrimitive.Title>}
          {description && (
            <AlertDialogPrimitive.Description
              className={descriptionStyles()}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {!hideActions && (
            <div className={actions()}>
              <AlertDialogCancel asChild>
                <Button variant="secondaryLight" size="small" onClick={handleClose} className={cancelButton()}>
                  {cancelButtonText}
                </Button>
              </AlertDialogCancel>

              <AlertDialogAction asChild>
                <Button size="small" onClick={handleConfirm} isLoading={isLoading}>
                  {confirmButtonText}
                </Button>
              </AlertDialogAction>
            </div>
          )}
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  );
};

const styles = tv({
  slots: {
    overlay: 'fixed inset-0 z-19 animate-dialog-overlay-show bg-black/50',
    content:
      'fixed left-1/2 top-1/2 z-19 max-h-[85vh] w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 transform animate-dialog-content-show rounded-lg bg-white p-6 shadow-xl focus:outline-none',
    titleStyles: 'm-0 text-baseLg font-bold',
    descriptionStyles: 'mb-0 pt-2 text-baseSm text-grey-600',
    actions: '-mx-6 -mb-6 mt-6 flex justify-end rounded-b-md border-t border-grey-200 bg-grey-50 px-6 py-4',
    cancelButton: 'mr-sm',
  },
});

// Exports
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = AlertDialogPrimitive.Content;
const AlertDialogTitle = AlertDialogPrimitive.Title;
const AlertDialogDescription = AlertDialogPrimitive.Description;
const AlertDialogAction = AlertDialogPrimitive.Action;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
};
