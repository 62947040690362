import { AIRPORT, AUTOCOMPLETE_ALGOLIA, SEAPORT } from '@/lib/constants';
import { AutocompletePlaceTypes } from '@/types';
import { AlgoliaPlaceInterface, IdentifierApiInterface, PlaceInterface } from '@/types/api-types';
import { formatPortIndentifers } from '@/utils/format/port-indentifers';
import { getPreferredIdentifier } from '@/utils/helpers';

/**
 * Formats a name and identifier into a consistent Algolia friendly format
 * @param name - name of port (i.e Melbourne)
 * @param preferredIdentifier - identifier to show in merge (ie. AUMEl)
 * @returns - formatted string
 */
export const formatAlgoliaName = (name: string, preferredIdentifier: IdentifierApiInterface): string => {
  return `${name} (${preferredIdentifier.value})`;
};

/**
 * Converts a Single Algolia Place to a Fluent Place
 * @param place - Algolia Place
 * @returns Fluent Place
 */
export const convertAlgoliaPlaceToPlace = (place: AlgoliaPlaceInterface): PlaceInterface => {
  const { type, ids, name, lat, lng, countryCode } = place;
  const identifier = getPreferredIdentifier(ids);
  const formattedName = identifier ? formatAlgoliaName(name, identifier) : '';
  const formattedIdentifier = identifier ? formatPortIndentifers([identifier]) : '';

  return {
    from: AUTOCOMPLETE_ALGOLIA,
    type,
    name: formattedName,
    shortName: name,
    countryCode,
    placeId: formattedIdentifier,
    location: {
      lat,
      lng,
    },
    identifier,
  };
};

/**
 * Converts an array Algolia Places to a Fluent Places
 * @param places[] - Algolia Place
 * @returns Fluent Place
 */
export const convertAlgoliaPlacesToPlaces = (places: AlgoliaPlaceInterface[]): PlaceInterface[] => {
  return places.map((p) => convertAlgoliaPlaceToPlace(p));
};

// Extracts a single Type facet from the Types array, if only one is set
export const getTypeFacet = (types: AutocompletePlaceTypes): string | null => {
  const filteredTypes = types.filter((type) => type === AIRPORT || type === SEAPORT);
  if (filteredTypes.length === 1) {
    return filteredTypes[0];
  }
  return null;
};
