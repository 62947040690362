import { get } from 'lodash';

import { countryNames } from '@/lib/constants/country-names';
import { RouteLocationInterface } from '@/types';
import { RouteLocationsApiV2Interface } from '@/types/api-types';
import { getPreferredIdentifier } from '@/utils/helpers';
import { getLocationName } from '@/utils/locations';

// Get location object by Location ID
export const formatRouteLocation = (
  locationId: string,
  locations: RouteLocationsApiV2Interface,
): RouteLocationInterface => {
  const location = locations[locationId];
  if (!location) throw new Error(`Could not find location: ${locationId}`);
  const { countryCode, location: position, identifiers, timezoneName, type } = location;

  const name = getLocationName(location);
  const countryName = getCountryName(countryCode) || '';

  return {
    name,
    identifiers,
    position,
    nodeReference: locationId,
    countryCode,
    countryName,
    timezoneName,
    type,
  };
};

export const getCountryName = (code: string): string => {
  return get(countryNames, code);
};

/**
 * Formats Port Object to `Port Name (Port Code)`
 * @param port Route location port
 * @returns String
 */
export const formatPortName = (port?: RouteLocationInterface | null): string => {
  if (!port) return '';

  const portCode = getPreferredIdentifier(port.identifiers)?.value;
  return `${port.name}${portCode ? ` (${portCode})` : ''}`;
};

/**
 * Handle Terminals text formatting
 */
export const formatTerminalName = (terminal: string): string => {
  if (terminal.toLowerCase().includes('terminal') || terminal.length > 10) {
    return terminal;
  }
  return `Terminal ${terminal}`;
};
