import { atom } from 'recoil';

import { ButtonGroupProps } from '@/components/button-group';

interface DialogStateInterface extends ButtonGroupProps {
  title?: string | null;
  description?: string; // Should be the default for single strings of text, used for accessibility
  body?: string; // Used when paragraphs and formatting are needed
  isOpen?: boolean;
  hasLogo?: boolean;
  onClose?: () => void;
  buttons?: ButtonGroupProps['buttons']; // Shouldn't need to do this, but getting a TS error in Storybook without it
  footnote?: string;
  maxWidth?: string;
}

export const dialogState = atom<DialogStateInterface>({
  key: 'dialogState',
  default: {
    title: null,
    description: undefined,
    body: undefined,
    isOpen: false,
    hasLogo: false,
    buttons: undefined,
    stackedButtons: false,
    onClose: () => {},
    footnote: undefined,
    maxWidth: undefined,
  },
});
