/**
 * Replaces spaces with plus signs. Used to make URLs more readable.
 *
 * @param string The string to format
 *
 * @returns The string with either + or spaces replaced
 */

export const replaceSpaceWithPlus = (string: string): string => {
  return string.replace(/\s/g, '+');
};
