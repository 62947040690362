import { omitBy } from 'lodash';
import { NextApiRequest } from 'next';

import { LinkInterface } from '@/types/block-types';
import { AllPostsPostInterface, PostInterface } from '@/types/cms-types';

// Checks if a string is a url
// Used for creating clickable content
const isValidUrl = (str: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

// Gets just the domain of a url for better UI presentation
const getUrlDomain = (url: string): string => {
  try {
    const { hostname } = new URL(url);
    return hostname.replace('www.', '');
  } catch (error: any) {
    return url;
  }
};

const cleanupExternalUrls = (urlString: string): string => {
  if (isValidUrl(urlString)) {
    // Ensure it has protocol, add if missing
    if (!urlString.match(/^[a-zA-Z]+:\/\//)) {
      return `https://${urlString}`;
    }
    // Return as is
    return urlString;
  }
  // Is not valid
  return '';
};

// Gets the origin from the window location
const getUrlOrigin = (): string =>
  typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

// Create a query string from an object key=value&key=value
const createQueryString = (params: Record<string, string | boolean>): string => {
  const paramsWithEmptyRemoved = omitBy(params, (param) => !param); // removes any empty or falsey values
  const string = Object.keys(paramsWithEmptyRemoved)
    .map((key) => `${key}=${encodeURIComponent(paramsWithEmptyRemoved[key])}`)
    .join('&');

  return string ? `?${string}` : '';
};

// Create a href for a link based on 2 manually created CMS fields
// 'internalLink' and 'externalLink'
const getDatoLinkHref = ({ internalLink, externalLink }: LinkInterface): string | undefined => {
  if (internalLink) {
    const { slug, parent } = internalLink;
    const parentSlug = parent?.slug;
    return parentSlug ? `/${parentSlug}/${slug}` : `/${slug}`;
  }

  return externalLink;
};

const getInternalDatoLinkHref = ({
  link,
  fallback,
}: {
  link: LinkInterface['internalLink'];
  fallback: string;
}): string => {
  if (link) {
    const { slug, parent } = link;
    const parentSlug = parent?.slug;
    return parentSlug ? `/${parentSlug}/${slug}` : `/${slug}`;
  }

  return `/${fallback}`;
};

const isLocalNetwork = (hostname = window.location.host) => {
  return (
    hostname.startsWith('localhost') ||
    hostname.startsWith('127.0.0.1') ||
    hostname.startsWith('192.168.') ||
    hostname.startsWith('10.0.') ||
    hostname.endsWith('.local')
  );
};

/**
 * Gets the environment url based on the request headers
 * @param req - NextApiRequest
 * @returns protocol, host, origin
 */
const getEnvironmentUrl = (req?: NextApiRequest) => {
  let host = req?.headers ? req.headers.host : window.location.host;
  let protocol = isLocalNetwork(host) ? 'http:' : 'https:';

  if (req && req.headers['x-forwarded-host'] && typeof req.headers['x-forwarded-host'] === 'string') {
    host = req.headers['x-forwarded-host'];
  }

  if (req && req.headers['x-forwarded-proto'] && typeof req.headers['x-forwarded-proto'] === 'string') {
    protocol = `${req.headers['x-forwarded-proto']}:`;
  }

  return {
    protocol,
    host,
    origin: `${protocol}//${host}`,
  };
};

/**
 * Get url for a single blog post
 */
const getPostUrl = (post: PostInterface | AllPostsPostInterface) => {
  if (!post) {
    return '';
  }

  return `/${post.postType}/${post.slug}`;
};

/**
 * Url to upgrade account
 */
const getUpgradeAccountUrl = () => {
  return `/account/organisation/billing`;
};

export {
  cleanupExternalUrls,
  createQueryString,
  getDatoLinkHref,
  getEnvironmentUrl,
  getInternalDatoLinkHref,
  getPostUrl,
  getUpgradeAccountUrl,
  getUrlDomain,
  getUrlOrigin,
  isValidUrl,
};
