/**
 * @NOTE: To use responsive styles, you must set size to 'null' and
 * then use the responsive classes for the SVG on the parent element.
 *
 * e.g.
 * <Icon name="arrow-down" size={null}
 *    className="[&_svg]:w-md [&_svg]:h-md [&_svg]:sm:w-lg [&_svg]:sm:h-md" />
 */

import { get } from 'lodash';
import { ReactSVG } from 'react-svg';

import { colors } from '@/theme/constants/colors';
import { space } from '@/theme/constants/space';
import { cn } from '@/utils/styles';

interface Props {
  className?: string;
  color?: string;
  transform?: string;
  name?: string;
  size?: string | number;
  wrapper?: 'span' | 'div' | 'svg';
}

const Icon = ({ className, color, transform, size, name, wrapper = 'span', ...props }: Props) => {
  const sizeValue = size ? space[size] || size : null;
  const colorValue = color ? get(colors, color) || color : null;

  const classes = cn(
    '[&_svg]:transition-transform',
    '[&_svg]:duration-150',
    '[&>div]:flex',
    '[&>span]:flex',
    className,
  );

  return (
    <ReactSVG
      className={classes}
      beforeInjection={(svg) => {
        // Workaround to allow us to use props for color and size
        svg.setAttribute(
          'style',
          `transform: ${transform}; width: ${sizeValue}; height: ${sizeValue}; fill: ${colorValue}`,
        );
      }}
      wrapper={wrapper}
      src={`/assets/icons/${name}.svg`}
      {...props}
    />
  );
};

export { Icon };
