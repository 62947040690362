import { ipapiProvider } from '@/lib/api/providers/ipapi';
import { UserDetails } from '@/types';
import { IpApiDetailsInterface } from '@/types/api-types';

/**
 * Gets anonymous user details based on IP
 * @returns UserDetails
 */
export const getUserDetailsByIp = async (): Promise<UserDetails> => {
  // Look up users details from IP service
  return ipapiProvider.query().then((result: IpApiDetailsInterface) => {
    // Transform to desired data shape?
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { country_name, country, region, city, latitude, longitude, timezone } = result;
    return {
      countryName: country_name,
      countryCode: country,
      region,
      city,
      timezone,
      position: {
        lat: latitude,
        lng: longitude,
      },
    };
  });
};
