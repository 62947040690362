/**
 * Opens a dialog to welcome new users
 *
 * Moved into a separate component to avoid duplcation of
 * this code in both app-layout and page-layout
 */

import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { COOKIE_ONBOARDED, NEXT_AUTH_STATUSES, SITE_NAME } from '@/lib/constants';
import { dialogState } from '@/state/dialog';
import { trackUser } from '@/utils/tracking';

const DialogWelcome = () => {
  const { data: session, status } = useSession();

  const pathname = usePathname();

  const setDialog = useSetRecoilState(dialogState);

  const hasBeenOnboarded = Cookies.get(COOKIE_ONBOARDED);

  // Check if the current page path starts with "/auth" as we don't want
  // to show the welcome dialog until they have been onboarded
  const isAuthPage = pathname?.startsWith('/auth');

  const isNewUser = session?.user?.isNewUser;

  const handleCloseWelcomeMessage = () => {
    Cookies.set(COOKIE_ONBOARDED, 'true', { expires: 7 });
  };

  const shouldShowWelcomeDialog =
    isNewUser && status === NEXT_AUTH_STATUSES.AUTHENTICATED && !hasBeenOnboarded && !isAuthPage;

  useEffect(() => {
    if (shouldShowWelcomeDialog) {
      // Log event that user was logged in successully
      trackUser.event('Create New Account (Success)', {
        location: 'Welcome Dialog',
      });
      // GA conversion event
      trackUser.conversion({ category: 'SignUp', action: 'NewAccountCreated' });

      // Wait a moment to show the welcome dialog to avoid flicker
      setTimeout(
        () =>
          setDialog({
            isOpen: true,
            title: `Welcome to ${SITE_NAME}`,
            body: `<p>At Fluent Cargo, we want to ensure freight forwarders and shippers have all crucial up to date information required before a booking is made. Quickly and easily explore every carrier, every mode, every route, every port, and every timetable to get your goods from any address to any address.</p>
            <p>Whether you are a small business owner looking to ship a few packages, or a large corporation with complex logistics needs, we have the tools and resources to save you hours upon hours of work when planning the most efficient way to get your goods from A to B. </p>`,
            hasLogo: true,
            footnote: `Whilst Fluent Cargo provides a planning and research tool for shipments and many other benefits, we are <b>not</b> a freight forwarder and can not make a booking on your behalf.`,
            buttons: [
              {
                text: 'Get started',
                onClick: () => {
                  handleCloseWelcomeMessage();
                  setDialog({ isOpen: false });
                },
                iconRight: 'arrow-right-alt',
              },
            ],
            onClose: () => handleCloseWelcomeMessage(),
            maxWidth: '600px',
          }),
        600,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewUser, status, hasBeenOnboarded, isAuthPage]);

  return <div />;
};

export { DialogWelcome };
