import Cookies from 'js-cookie';
import { NextApiRequest } from 'next';

import { COOKIE_API_URL } from '@/lib/constants';

const QAYAQ_TEST_API_URL = process.env.NEXT_PUBLIC_QAYAQ_TESTING_API_URL;

// helper to set QAYAQ_TEST_API_URL
export const setQayaqTestApiUrl = (useTestApi = false) => {
  if (useTestApi) {
    setApiUrl(QAYAQ_TEST_API_URL);
  } else {
    clearApiUrl();
  }
};

// Sets an API url to use
// Anticipates multiple urls/servers that may be used in the future
// For now we only have the one
export const setApiUrl = (url: string) => {
  Cookies.set(COOKIE_API_URL, url);
};

// Gets stored API url from passed in cookies or from lib
export const getApiUrl = (requestCookies?: NextApiRequest['cookies']) => {
  if (requestCookies) {
    // Handle server side cookies
    return requestCookies[COOKIE_API_URL];
  }

  if (typeof window !== 'undefined') {
    // Ensure this doesn't accidentally get called server side
    return Cookies.get(COOKIE_API_URL) || null;
  }

  return null;
};

// Clears the stored API url
export const clearApiUrl = () => {
  Cookies.remove(COOKIE_API_URL);
};
