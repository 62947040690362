import * as DialogPrimitive from '@radix-ui/react-dialog';
import { isEmpty } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { ButtonGroup, ButtonGroupProps } from '@/components/button-group';
import { Icon } from '@/components/icon';
import { VisuallyHidden } from '@/components/visually-hidden';
import { dialogPortalState } from '@/state/user';
import { tv } from '@/utils/styles';

export interface DialogContentProps extends DialogPrimitive.DialogProps, ButtonGroupProps {
  onClose?: () => void;
  stackedButtons?: boolean;
  footnote?: string;
  maxWidth?: string;
}

const DialogContent = React.forwardRef<HTMLDivElement, DialogContentProps>(
  ({ children, buttons, stackedButtons, footnote, onClose, maxWidth = '500px', className, ...props }, forwardedRef) => {
    const dialogPortal = useRecoilValue(dialogPortalState);

    const { overlay, contentWrapper, content, close, footnoteStyles } = styles();

    return (
      <DialogPrimitive.Portal container={dialogPortal}>
        <DialogPrimitive.Overlay className={overlay()} onClick={onClose} />
        <DialogPrimitive.Content asChild onEscapeKeyDown={onClose} ref={forwardedRef} {...props}>
          <div className={contentWrapper({ className })} style={{ maxWidth: maxWidth ? `${maxWidth}` : undefined }}>
            <div className={content()}>
              {children}

              {!isEmpty(buttons) && <ButtonGroup buttons={buttons} stackedButtons={stackedButtons} className="mt-lg" />}
              {/* eslint-disable-next-line react/no-danger */}
              {footnote && <small className={footnoteStyles()} dangerouslySetInnerHTML={{ __html: footnote }} />}
              <button className={close()} type="button" onClick={onClose}>
                <Icon name="close" size="md" color="text.primary" />
                <VisuallyHidden>Close</VisuallyHidden>
              </button>
            </div>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  },
);

DialogContent.displayName = 'DialogContent';

const styles = tv({
  slots: {
    overlay:
      'fixed inset-0 z-10 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
    contentWrapper:
      'fixed left-1/2 top-1/2 z-11 grid max-h-[85vh] w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 overflow-auto bg-white shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
    content: 'px-10 py-8 text-left',
    footnoteStyles: 'mt-6 block text-grey-500',
    close: 'absolute right-8 top-8 hover:[&_svg]:!fill-states-error-color',
  },
});

export { DialogContent };
