import { endOfDay } from 'date-fns';
import Cookies from 'js-cookie';

import { COOKIE_MAX_SEARCH_COUNT } from '@/lib/constants';

/**
 * Gets the current search count
 * @returns number
 */
export const getUserSearchCount = (): number => {
  return Number(Cookies.get(COOKIE_MAX_SEARCH_COUNT)) || 0;
};

export const setUserSearchCount = (count: number) => {
  Cookies.set(COOKIE_MAX_SEARCH_COUNT, `${count}`, { expires: endOfDay(new Date()) });
};

/**
 * Updates the users search count
 * @param initialCount current count (optional)
 */
export const incrementUserSearchCount = (initialCount?: number) => {
  const count = initialCount || getUserSearchCount();

  setUserSearchCount(count + 1);
};

export const initialiseUserSearchCount = () => {
  if (getUserSearchCount() > 0) return;

  setUserSearchCount(0);
};
