interface TransitionsInterface {
  [key: string]: string;
}

const transitions: TransitionsInterface = {
  linear: 'linear',
  in: 'cubic-bezier(.42, 0, 1 , 1)',
  out: 'cubic-bezier(0, 0 ,.58, 1)',
  'in-out': 'cubic-bezier(.42, 0, .58, 1)',
  'ease-out-back': 'cubic-bezier(0.17, 0.89, 0.33, 1.29)',
};

export { transitions };
