import { get, isArray } from 'lodash';

import { API_STATUS_CODE, MAINTENANCE_URL } from '@/lib/constants';
import { AdvancedSearchParams, ContactFormInputsInterface, LocationInterface } from '@/types';
import { UsersPublicTableInterface } from '@/types/database-types';
import { logEvent } from '@/utils/logger';

import { shouldLogError, shouldLogInfo } from './should-log-error';

// @TODO: This is getting out of hand, need to refactor this
interface RequestParamsInterface {
  body?:
    | {
        email: string;
        recaptchaToken?: string;
      }
    | {
        email: string;
        status: string;
      }
    | {
        email: string;
        password: string;
        token: string;
      }
    | {
        origin: LocationInterface;
        destination: LocationInterface;
        params: AdvancedSearchParams;
        features: string;
      }
    | {
        user: UsersPublicTableInterface;
      }
    | ContactFormInputsInterface
    | any;
}

interface RequestConfigInterface {
  method: string;
  mode: RequestMode;
  body?: string;
}

type RestMethodInterface = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const request = async <T>(
  requestUrl: string,
  params?: RequestParamsInterface,
  method?: RestMethodInterface,
  headers: Record<string, string> = { 'content-type': 'application/json' },
): Promise<T> => {
  const body = get(params, 'body');
  const requestMethod = method || (body ? 'POST' : 'GET');

  let config = {
    method: requestMethod,
    mode: 'cors',
    headers,
  } as RequestConfigInterface;

  if (body) {
    config = {
      ...config,
      body: JSON.stringify(body),
    };
  }

  const response = await fetch(requestUrl, config);

  // Get server timings
  const serverTiming = response.headers.get('server-timing');
  if (response.status === API_STATUS_CODE.MAINTENANCE) {
    if (window !== undefined && window.location.pathname !== MAINTENANCE_URL) {
      window.location.href = MAINTENANCE_URL;
    }
    throw new Error('Down for maintenance');
  }

  // Get Json body
  const json = await response.json();

  if (!response.ok) {
    const apiError = new Error(json.error);
    logEvent.error('Api Request Error', { requestUrl, ...apiError });
    throw apiError;
  }

  if (shouldLogError(json)) {
    logEvent.error('Api Response Error', { requestUrl, ...json });
  }

  if (shouldLogInfo(json)) {
    logEvent.info(json.error, { requestUrl, ...json });
  }

  if (isArray(json)) {
    // @TODO figure out if this fix is gonna break anything else?
    return json as T;
  }

  return { ...json, ...(serverTiming ? { serverTiming } : {}) };
};

export { request };
