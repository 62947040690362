import { LocationInterface } from '@/types';

export const places: LocationInterface[] = [
  {
    type: 'city',
    name: 'Melbourne',
    countryCode: 'AU',
    placeId: 'melbourne-au',
    location: { lat: -37.82, lng: 144.975 },
  },
  {
    type: 'city',
    name: 'Brisbane',
    countryCode: 'AU',
    placeId: 'brisbane-au',
    location: { lat: -27.455, lng: 153.0351 },
  },
  {
    type: 'city',
    name: 'Sydney',
    countryCode: 'AU',
    placeId: 'sydney-au',
    location: { lat: -33.92, lng: 151.1852 },
  },
  {
    type: 'city',
    name: 'Perth',
    countryCode: 'AU',
    placeId: 'perth-au',
    location: { lat: -31.955, lng: 115.84 },
  },
  {
    type: 'city',
    name: 'Adelaide',
    countryCode: 'AU',
    placeId: 'adelaide-au',
    location: { lat: -34.935, lng: 138.6 },
  },
  {
    type: 'city',
    name: 'Auckland',
    countryCode: 'NZ',
    placeId: 'auckland-nz',
    location: { lat: -36.8481, lng: 174.763 },
  },
  {
    type: 'city',
    name: 'Wellington',
    countryCode: 'NZ',
    placeId: 'wellington-nz',
    location: { lat: -41.3, lng: 174.7833 },
  },
  {
    type: 'city',
    name: 'Christchurch',
    countryCode: 'NZ',
    placeId: 'christchurch-nz',
    location: { lat: -43.535, lng: 172.63 },
  },
  {
    type: 'city',
    name: 'Tokyo',
    countryCode: 'JP',
    placeId: 'tokyo-jp',
    location: { lat: 35.685, lng: 139.7514 },
  },
  {
    type: 'city',
    name: 'Yokohama',
    countryCode: 'JP',
    placeId: 'yokohama-jp',
    location: { lat: 35.32, lng: 139.58 },
  },
  {
    type: 'city',
    name: 'Ōsaka',
    countryCode: 'JP',
    placeId: 'osaka-jp',
    location: { lat: 34.75, lng: 135.4601 },
  },
  {
    type: 'city',
    name: 'Kōbe',
    countryCode: 'JP',
    placeId: 'kobe-jp',
    location: { lat: 34.68, lng: 135.17 },
  },
  {
    type: 'city',
    name: 'Nagoya',
    countryCode: 'JP',
    placeId: 'nagoya-jp',
    location: { lat: 35.155, lng: 136.915 },
  },
  {
    type: 'city',
    name: 'Hiroshima',
    countryCode: 'JP',
    placeId: 'hiroshima-jp',
    location: { lat: 34.3878, lng: 132.4429 },
  },
  {
    type: 'city',
    name: 'Sapporo',
    countryCode: 'JP',
    placeId: 'sapporo-jp',
    location: { lat: 43.075, lng: 141.34 },
  },
  {
    type: 'city',
    name: 'Singapore',
    countryCode: 'SG',
    placeId: 'singapore-sg',
    location: { lat: 1.293, lng: 103.8558 },
  },
  {
    type: 'city',
    name: 'Kuala Lumpur',
    countryCode: 'MY',
    placeId: 'kuala-lumpur-my',
    location: { lat: 3.1667, lng: 101.7 },
  },
  {
    type: 'city',
    name: 'Kaohsiung',
    countryCode: 'TW',
    placeId: 'kaohsiung-tw',
    location: { lat: 22.6333, lng: 120.2666 },
  },
  {
    type: 'city',
    name: 'Taipei',
    countryCode: 'TW',
    placeId: 'taipei-tw',
    location: { lat: 25.0358, lng: 121.5683 },
  },
  {
    type: 'city',
    name: 'Taichung',
    countryCode: 'TW',
    placeId: 'taichung-tw',
    location: { lat: 24.1521, lng: 120.6817 },
  },
  {
    type: 'city',
    name: 'Hanoi',
    countryCode: 'VN',
    placeId: 'hanoi-vn',
    location: { lat: 21.0333, lng: 105.85 },
  },
  {
    type: 'city',
    name: 'Ho Chi Minh City',
    countryCode: 'VN',
    placeId: 'ho-chi-minh-city-vn',
    location: { lat: 10.78, lng: 106.695 },
  },
  {
    type: 'city',
    name: 'Beijing',
    countryCode: 'CN',
    placeId: 'beijing-cn',
    location: { lat: 39.9289, lng: 116.3883 },
  },
  {
    type: 'city',
    name: 'Dalian',
    countryCode: 'CN',
    placeId: 'dalian-cn',
    location: { lat: 38.9228, lng: 121.6298 },
  },
  {
    type: 'city',
    name: 'Tianjin',
    countryCode: 'CN',
    placeId: 'tianjin-cn',
    location: { lat: 39.13, lng: 117.2 },
  },
  {
    type: 'city',
    name: 'Ningbo',
    countryCode: 'CN',
    placeId: 'ningbo-cn',
    location: { lat: 29.88, lng: 121.55 },
  },
  {
    type: 'city',
    name: 'Chengdu',
    countryCode: 'CN',
    placeId: 'chengdu-cn',
    location: { lat: 30.67, lng: 104.07 },
  },
  {
    type: 'city',
    name: 'Wuhan',
    countryCode: 'CN',
    placeId: 'wuhan-cn',
    location: { lat: 30.58, lng: 114.27 },
  },
  {
    type: 'city',
    name: 'Foshan',
    countryCode: 'CN',
    placeId: 'foshan-cn',
    location: { lat: 23.0301, lng: 113.12 },
  },
  {
    type: 'city',
    name: 'Chongqing',
    countryCode: 'CN',
    placeId: 'chongqing-cn',
    location: { lat: 29.565, lng: 106.595 },
  },
  {
    type: 'city',
    name: 'Qingdao',
    countryCode: 'CN',
    placeId: 'qingdao-cn',
    location: { lat: 36.09, lng: 120.33 },
  },
  {
    type: 'city',
    name: 'Shanghai',
    countryCode: 'CN',
    placeId: 'shanghai-cn',
    location: { lat: 31.2165, lng: 121.4365 },
  },
  {
    type: 'city',
    name: 'Shenzhen',
    countryCode: 'CN',
    placeId: 'shenzhen-cn',
    location: { lat: 22.5524, lng: 114.1221 },
  },
  {
    type: 'city',
    name: 'Guangzhou',
    countryCode: 'CN',
    placeId: 'guangzhou-cn',
    location: { lat: 23.145, lng: 113.325 },
  },
  {
    type: 'city',
    name: 'Hong Kong',
    countryCode: 'HK',
    placeId: 'hong-kong-hk',
    location: { lat: 22.305, lng: 114.185 },
  },
  {
    type: 'city',
    name: 'Macau',
    countryCode: 'MO',
    placeId: 'macau-mo',
    location: { lat: 22.203, lng: 113.545 },
  },
  {
    type: 'city',
    name: 'Busan',
    countryCode: 'KR',
    placeId: 'busan-kr',
    location: { lat: 35.0951, lng: 129.01 },
  },
  {
    type: 'city',
    name: 'Seoul',
    countryCode: 'KR',
    placeId: 'seoul-kr',
    location: { lat: 37.5663, lng: 126.9997 },
  },
  {
    type: 'city',
    name: 'Manila',
    countryCode: 'PH',
    placeId: 'manila-ph',
    location: { lat: 14.6042, lng: 120.9822 },
  },
  {
    type: 'city',
    name: 'Jakarta',
    countryCode: 'ID',
    placeId: 'jakarta-id',
    location: { lat: -6.1744, lng: 106.8294 },
  },
  {
    type: 'city',
    name: 'Surabaya',
    countryCode: 'ID',
    placeId: 'surabaya-id',
    location: { lat: -7.2492, lng: 112.7508 },
  },
  {
    type: 'city',
    name: 'Phnom Penh',
    countryCode: 'KH',
    placeId: 'phnom-penh-kh',
    location: { lat: 11.55, lng: 104.9166 },
  },
  {
    type: 'city',
    name: 'Bangkok',
    countryCode: 'TH',
    placeId: 'bangkok-th',
    location: { lat: 13.75, lng: 100.5166 },
  },
  {
    type: 'city',
    name: 'Los Angeles',
    countryCode: 'US',
    placeId: 'los-angeles-us',
    location: { lat: 34.1139, lng: -118.4068 },
  },
  {
    type: 'city',
    name: 'San Francisco',
    countryCode: 'US',
    placeId: 'san-francisco-us',
    location: { lat: 37.7562, lng: -122.443 },
  },
  {
    type: 'city',
    name: 'San Diego',
    countryCode: 'US',
    placeId: 'san-diego-us',
    location: { lat: 32.8312, lng: -117.1225 },
  },
  {
    type: 'city',
    name: 'Oakland',
    countryCode: 'US',
    placeId: 'oakland-us',
    location: { lat: 37.7903, lng: -122.2165 },
  },
  {
    type: 'city',
    name: 'Honolulu',
    countryCode: 'US',
    placeId: 'honolulu-us',
    location: { lat: 21.3294, lng: -157.846 },
  },
  {
    type: 'city',
    name: 'Seattle',
    countryCode: 'US',
    placeId: 'seattle-us',
    location: { lat: 47.6211, lng: -122.3244 },
  },
  {
    type: 'city',
    name: 'Detroit',
    countryCode: 'US',
    placeId: 'detroit-us',
    location: { lat: 42.3834, lng: -83.1024 },
  },
  {
    type: 'city',
    name: 'Dallas',
    countryCode: 'US',
    placeId: 'dallas-us',
    location: { lat: 32.7936, lng: -96.7662 },
  },
  {
    type: 'city',
    name: 'Fort Worth',
    countryCode: 'US',
    placeId: 'fort-worth-us',
    location: { lat: 32.7812, lng: -97.3472 },
  },
  {
    type: 'city',
    name: 'Houston',
    countryCode: 'US',
    placeId: 'houston-us',
    location: { lat: 29.7869, lng: -95.3905 },
  },
  {
    type: 'city',
    name: 'New Orleans',
    countryCode: 'US',
    placeId: 'new-orleans-us',
    location: { lat: 30.0687, lng: -89.9288 },
  },
  {
    type: 'city',
    name: 'Portland',
    countryCode: 'US',
    placeId: 'portland-oregan-us',
    location: { lat: 45.5371, lng: -122.65 },
  },
  {
    type: 'city',
    name: 'Anchorage',
    countryCode: 'US',
    placeId: 'anchorage-us',
    location: { lat: 61.1508, lng: -149.1091 },
  },
  {
    type: 'city',
    name: 'Tampa',
    countryCode: 'US',
    placeId: 'tampa-us',
    location: { lat: 27.9942, lng: -82.4451 },
  },
  {
    type: 'city',
    name: 'Jacksonville',
    countryCode: 'US',
    placeId: 'jacksonville-us',
    location: { lat: 30.3322, lng: -81.6749 },
  },
  {
    type: 'city',
    name: 'Miami',
    countryCode: 'US',
    placeId: 'miami-us',
    location: { lat: 25.7839, lng: -80.2102 },
  },
  {
    type: 'city',
    name: 'Cincinnati',
    countryCode: 'US',
    placeId: 'cincinnati-us',
    location: { lat: 39.1412, lng: -84.506 },
  },
  {
    type: 'city',
    name: 'Savannah',
    countryCode: 'US',
    placeId: 'savannah-us',
    location: { lat: 32.0281, lng: -81.1785 },
  },
  {
    type: 'city',
    name: 'Charleston',
    countryCode: 'US',
    placeId: 'charleston-us',
    location: { lat: 32.8151, lng: -79.963 },
  },
  {
    type: 'city',
    name: 'Charlotte',
    countryCode: 'US',
    placeId: 'charlotte-us',
    location: { lat: 35.2079, lng: -80.8304 },
  },
  {
    type: 'city',
    name: 'San Antonio',
    countryCode: 'US',
    placeId: 'san-antonio-us',
    location: { lat: 29.4658, lng: -98.5254 },
  },
  {
    type: 'city',
    name: 'Norfolk',
    countryCode: 'US',
    placeId: 'norfolk-us',
    location: { lat: 36.8945, lng: -76.259 },
  },
  {
    type: 'city',
    name: 'Indianapolis',
    countryCode: 'US',
    placeId: 'indianapolis-us',
    location: { lat: 39.7771, lng: -86.1458 },
  },
  {
    type: 'city',
    name: 'Baltimore',
    countryCode: 'US',
    placeId: 'baltimore-us',
    location: { lat: 39.3051, lng: -76.6144 },
  },
  {
    type: 'city',
    name: 'New York',
    countryCode: 'US',
    placeId: 'new-york-us',
    location: { lat: 40.6943, lng: -73.9249 },
  },
  {
    type: 'city',
    name: 'Philadelphia',
    countryCode: 'US',
    placeId: 'philadelphia-us',
    location: { lat: 40.0077, lng: -75.1339 },
  },
  {
    type: 'city',
    name: 'St. Louis',
    countryCode: 'US',
    placeId: 'st-louis-us',
    location: { lat: 38.6358, lng: -90.2451 },
  },
  {
    type: 'city',
    name: 'Atlanta',
    countryCode: 'US',
    placeId: 'atlanta-us',
    location: { lat: 33.7627, lng: -84.4225 },
  },
  {
    type: 'city',
    name: 'Chicago',
    countryCode: 'US',
    placeId: 'chicago-us',
    location: { lat: 41.8373, lng: -87.6862 },
  },
  {
    type: 'city',
    name: 'Albuquerque',
    countryCode: 'US',
    placeId: 'albuquerque-us',
    location: { lat: 35.1053, lng: -106.6464 },
  },
  {
    type: 'city',
    name: 'Phoenix',
    countryCode: 'US',
    placeId: 'phoenix-us',
    location: { lat: 33.5722, lng: -112.0891 },
  },
  {
    type: 'city',
    name: 'Las Vegas',
    countryCode: 'US',
    placeId: 'las-vegas-us',
    location: { lat: 36.2333, lng: -115.2654 },
  },
  {
    type: 'city',
    name: 'Boston',
    countryCode: 'US',
    placeId: 'boston-us',
    location: { lat: 42.3188, lng: -71.0846 },
  },
  {
    type: 'city',
    name: 'Austin',
    countryCode: 'US',
    placeId: 'austin-us',
    location: { lat: 30.3006, lng: -97.7517 },
  },
  {
    type: 'city',
    name: 'Salt Lake City',
    countryCode: 'US',
    placeId: 'salt-lake-city-us',
    location: { lat: 40.7774, lng: -111.93 },
  },
  {
    type: 'city',
    name: 'Halifax',
    countryCode: 'CA',
    placeId: 'halifax-ca',
    location: { lat: 44.65, lng: -63.6 },
  },
  {
    type: 'city',
    name: 'Calgary',
    countryCode: 'CA',
    placeId: 'calgary-ca',
    location: { lat: 51.083, lng: -114.08 },
  },
  {
    type: 'city',
    name: 'Vancouver',
    countryCode: 'CA',
    placeId: 'vancouver-ca',
    location: { lat: 49.2734, lng: -123.1216 },
  },
  {
    type: 'city',
    name: 'Montréal',
    countryCode: 'CA',
    placeId: 'montreal-ca',
    location: { lat: 45.5, lng: -73.5833 },
  },
  {
    type: 'city',
    name: 'Québec',
    countryCode: 'CA',
    placeId: 'quebec-ca',
    location: { lat: 46.84, lng: -71.2456 },
  },
  {
    type: 'city',
    name: 'Toronto',
    countryCode: 'CA',
    placeId: 'toronto-ca',
    location: { lat: 43.7, lng: -79.42 },
  },
  {
    type: 'city',
    name: 'Mexico City',
    countryCode: 'MX',
    placeId: 'mexico-city-mx',
    location: { lat: 19.4424, lng: -99.131 },
  },
  {
    type: 'city',
    name: 'Ensenada',
    countryCode: 'MX',
    placeId: 'ensenada-mx',
    location: { lat: 31.87, lng: -116.62 },
  },
  {
    type: 'city',
    name: 'Juárez',
    countryCode: 'MX',
    placeId: 'juarez-mx',
    location: { lat: 31.6904, lng: -106.49 },
  },
  {
    type: 'city',
    name: 'Veracruz',
    countryCode: 'MX',
    placeId: 'veracruz-mx',
    location: { lat: 19.1773, lng: -96.16 },
  },
  {
    type: 'city',
    name: 'Manzanillo',
    countryCode: 'MX',
    placeId: 'manzanillo-mx',
    location: { lat: 19.0496, lng: -104.3231 },
  },
  {
    type: 'city',
    name: 'Madrid',
    countryCode: 'ES',
    placeId: 'madrid-es',
    location: { lat: 40.4, lng: -3.6834 },
  },
  {
    type: 'city',
    name: 'Valencia',
    countryCode: 'ES',
    placeId: 'valencia-es',
    location: { lat: 39.485, lng: -0.4 },
  },
  {
    type: 'city',
    name: 'Algeciras',
    countryCode: 'ES',
    placeId: 'algeciras-es',
    location: { lat: 36.1267, lng: -5.4665 },
  },
  {
    type: 'city',
    name: 'Barcelona',
    countryCode: 'ES',
    placeId: 'barcelona-es',
    location: { lat: 41.3833, lng: 2.1834 },
  },
  {
    type: 'city',
    name: 'Tarragona',
    countryCode: 'ES',
    placeId: 'tarragona-es',
    location: { lat: 41.1204, lng: 1.25 },
  },
  {
    type: 'city',
    name: 'Zaragoza',
    countryCode: 'ES',
    placeId: 'zaragoza-es',
    location: { lat: 41.65, lng: -0.89 },
  },
  {
    type: 'city',
    name: 'Bilbao',
    countryCode: 'ES',
    placeId: 'bilbao-es',
    location: { lat: 43.25, lng: -2.93 },
  },
  {
    type: 'city',
    name: 'Las Palmas de Gran Canaria',
    countryCode: 'ES',
    placeId: 'las-palmas-de-gran-canaria-es',
    location: { lat: 28.1, lng: -15.43 },
  },
  {
    type: 'city',
    name: 'Porto',
    countryCode: 'PT',
    placeId: 'porto-pt',
    location: { lat: 41.15, lng: -8.62 },
  },
  {
    type: 'city',
    name: 'Lisbon',
    countryCode: 'PT',
    placeId: 'lisbon-pt',
    location: { lat: 38.7227, lng: -9.1449 },
  },
  {
    type: 'city',
    name: 'Le Havre',
    countryCode: 'FR',
    placeId: 'le-havre-fr',
    location: { lat: 49.505, lng: 0.105 },
  },
  {
    type: 'city',
    name: 'Marseille',
    countryCode: 'FR',
    placeId: 'marseille-fr',
    location: { lat: 43.29, lng: 5.375 },
  },
  {
    type: 'city',
    name: 'Toulouse',
    countryCode: 'FR',
    placeId: 'toulouse-fr',
    location: { lat: 43.62, lng: 1.4499 },
  },
  {
    type: 'city',
    name: 'Nantes',
    countryCode: 'FR',
    placeId: 'nantes-fr',
    location: { lat: 47.2104, lng: -1.59 },
  },
  {
    type: 'city',
    name: 'Bordeaux',
    countryCode: 'FR',
    placeId: 'bordeaux-fr',
    location: { lat: 44.85, lng: -0.595 },
  },
  {
    type: 'city',
    name: 'Nice',
    countryCode: 'FR',
    placeId: 'nice-fr',
    location: { lat: 43.715, lng: 7.265 },
  },
  {
    type: 'city',
    name: 'Lyon',
    countryCode: 'FR',
    placeId: 'lyon-fr',
    location: { lat: 45.77, lng: 4.83 },
  },
  {
    type: 'city',
    name: 'Lille',
    countryCode: 'FR',
    placeId: 'lille-fr',
    location: { lat: 50.65, lng: 3.08 },
  },
  {
    type: 'city',
    name: 'Rouen',
    countryCode: 'FR',
    placeId: 'rouen-fr',
    location: { lat: 49.4304, lng: 1.08 },
  },
  {
    type: 'city',
    name: 'Reims',
    countryCode: 'FR',
    placeId: 'reims-fr',
    location: { lat: 49.2504, lng: 4.03 },
  },
  {
    type: 'city',
    name: 'Paris',
    countryCode: 'FR',
    placeId: 'paris-fr',
    location: { lat: 48.8667, lng: 2.3333 },
  },
  {
    type: 'city',
    name: 'Strasbourg',
    countryCode: 'FR',
    placeId: 'strasbourg-fr',
    location: { lat: 48.58, lng: 7.75 },
  },
  {
    type: 'city',
    name: 'Antwerp',
    countryCode: 'BE',
    placeId: 'antwerp-be',
    location: { lat: 51.2204, lng: 4.415 },
  },
  {
    type: 'city',
    name: 'Ghent',
    countryCode: 'BE',
    placeId: 'ghent-be',
    location: { lat: 51.03, lng: 3.7 },
  },
  {
    type: 'city',
    name: 'Brussels',
    countryCode: 'BE',
    placeId: 'brussels-be',
    location: { lat: 50.8333, lng: 4.3333 },
  },
  {
    type: 'city',
    name: 'London',
    countryCode: 'GB',
    placeId: 'london-gb',
    location: { lat: 51.5, lng: -0.1167 },
  },
  {
    type: 'city',
    name: 'Cardiff',
    countryCode: 'GB',
    placeId: 'cardiff-gb',
    location: { lat: 51.5, lng: -3.225 },
  },
  {
    type: 'city',
    name: 'Southampton',
    countryCode: 'GB',
    placeId: 'southampton-gb',
    location: { lat: 50.9, lng: -1.4 },
  },
  {
    type: 'city',
    name: 'Liverpool',
    countryCode: 'GB',
    placeId: 'liverpool-gb',
    location: { lat: 53.416, lng: -2.918 },
  },
  {
    type: 'city',
    name: 'Manchester',
    countryCode: 'GB',
    placeId: 'manchester-gb',
    location: { lat: 53.5004, lng: -2.248 },
  },
  {
    type: 'city',
    name: 'Birmingham',
    countryCode: 'GB',
    placeId: 'birmingham-gb',
    location: { lat: 52.475, lng: -1.92 },
  },
  {
    type: 'city',
    name: 'Glasgow',
    countryCode: 'GB',
    placeId: 'glasgow-gb',
    location: { lat: 55.8744, lng: -4.2507 },
  },
  {
    type: 'city',
    name: 'Edinburgh',
    countryCode: 'GB',
    placeId: 'edinburgh-gb',
    location: { lat: 55.9483, lng: -3.2191 },
  },
  {
    type: 'city',
    name: 'Belfast',
    countryCode: 'GB',
    placeId: 'belfast-gb',
    location: { lat: 54.6, lng: -5.96 },
  },
  {
    type: 'city',
    name: 'Bristol',
    countryCode: 'GB',
    placeId: 'bristol-gb',
    location: { lat: 51.45, lng: -2.5833 },
  },
  {
    type: 'city',
    name: 'Newcastle upon Tyne',
    countryCode: 'GB',
    placeId: 'newcastle-upon-tyne-gb',
    location: { lat: 55.0004, lng: -1.6 },
  },
  {
    type: 'city',
    name: 'Dublin',
    countryCode: 'IE',
    placeId: 'dublin-ie',
    location: { lat: 53.3331, lng: -6.2489 },
  },
  {
    type: 'city',
    name: 'Reykjavík',
    countryCode: 'IS',
    placeId: 'reykjavik-is',
    location: { lat: 64.15, lng: -21.95 },
  },
  {
    type: 'city',
    name: 'Nuuk',
    countryCode: 'GL',
    placeId: 'nuuk-gl',
    location: { lat: 64.1983, lng: -51.7327 },
  },
  {
    type: 'city',
    name: 'Rotterdam',
    countryCode: 'NL',
    placeId: 'rotterdam-nl',
    location: { lat: 51.92, lng: 4.48 },
  },
  {
    type: 'city',
    name: 'Amsterdam',
    countryCode: 'NL',
    placeId: 'amsterdam-nl',
    location: { lat: 52.35, lng: 4.9166 },
  },
  {
    type: 'city',
    name: 'Munich',
    countryCode: 'DE',
    placeId: 'munich-de',
    location: { lat: 48.1299, lng: 11.575 },
  },
  {
    type: 'city',
    name: 'Berlin',
    countryCode: 'DE',
    placeId: 'berlin-de',
    location: { lat: 52.5218, lng: 13.4015 },
  },
  {
    type: 'city',
    name: 'Stuttgart',
    countryCode: 'DE',
    placeId: 'stuttgart-de',
    location: { lat: 48.78, lng: 9.2 },
  },
  {
    type: 'city',
    name: 'Frankfurt',
    countryCode: 'DE',
    placeId: 'frankfurt-de',
    location: { lat: 50.1, lng: 8.675 },
  },
  {
    type: 'city',
    name: 'Hamburg',
    countryCode: 'DE',
    placeId: 'hamburg-de',
    location: { lat: 53.55, lng: 10 },
  },
  {
    type: 'city',
    name: 'Leipzig',
    countryCode: 'DE',
    placeId: 'leipzig-de',
    location: { lat: 51.3354, lng: 12.41 },
  },
  {
    type: 'city',
    name: 'Bremerhaven',
    countryCode: 'DE',
    placeId: 'bremerhaven-de',
    location: { lat: 53.5504, lng: 8.58 },
  },
  {
    type: 'city',
    name: 'Dresden',
    countryCode: 'DE',
    placeId: 'dresden-de',
    location: { lat: 51.05, lng: 13.75 },
  },
  {
    type: 'city',
    name: 'Luxembourg City',
    countryCode: 'LU',
    placeId: 'luxembourg-lu',
    location: { lat: 49.6117, lng: 6.13 },
  },
  {
    type: 'city',
    name: 'Vienna',
    countryCode: 'AT',
    placeId: 'vienna-at',
    location: { lat: 48.2, lng: 16.3666 },
  },
  {
    type: 'city',
    name: 'Göteborg',
    countryCode: 'SE',
    placeId: 'goteborg-se',
    location: { lat: 57.75, lng: 12 },
  },
  {
    type: 'city',
    name: 'Stockholm',
    countryCode: 'SE',
    placeId: 'stockholm-se',
    location: { lat: 59.3508, lng: 18.0973 },
  },
  {
    type: 'city',
    name: 'Malmö',
    countryCode: 'SE',
    placeId: 'malmo-se',
    location: { lat: 55.5833, lng: 13.0333 },
  },
  {
    type: 'city',
    name: 'Copenhagen',
    countryCode: 'DK',
    placeId: 'copenhagen-dk',
    location: { lat: 55.6786, lng: 12.5635 },
  },
  {
    type: 'city',
    name: 'Aarhus',
    countryCode: 'DK',
    placeId: 'aarhus-dk',
    location: { lat: 56.1572, lng: 10.2107 },
  },
  {
    type: 'city',
    name: 'Helsinki',
    countryCode: 'FI',
    placeId: 'helsinki-fi',
    location: { lat: 60.1756, lng: 24.9341 },
  },
  {
    type: 'city',
    name: 'Oslo',
    countryCode: 'NO',
    placeId: 'oslo-no',
    location: { lat: 59.9167, lng: 10.75 },
  },
  {
    type: 'city',
    name: 'Zürich',
    countryCode: 'CH',
    placeId: 'zurich-ch',
    location: { lat: 47.38, lng: 8.55 },
  },
  {
    type: 'city',
    name: 'Genoa',
    countryCode: 'IT',
    placeId: 'genoa-it',
    location: { lat: 44.41, lng: 8.93 },
  },
  {
    type: 'city',
    name: 'Venice',
    countryCode: 'IT',
    placeId: 'venice-it',
    location: { lat: 45.4387, lng: 12.335 },
  },
  {
    type: 'city',
    name: 'Naples',
    countryCode: 'IT',
    placeId: 'naples-it',
    location: { lat: 40.84, lng: 14.245 },
  },
  {
    type: 'city',
    name: 'Rome',
    countryCode: 'IT',
    placeId: 'rome-it',
    location: { lat: 41.896, lng: 12.4833 },
  },
  {
    type: 'city',
    name: 'Turin',
    countryCode: 'IT',
    placeId: 'turin-it',
    location: { lat: 45.0704, lng: 7.67 },
  },
  {
    type: 'city',
    name: 'Trento',
    countryCode: 'IT',
    placeId: 'trento-it',
    location: { lat: 46.0804, lng: 11.12 },
  },
  {
    type: 'city',
    name: 'Bari',
    countryCode: 'IT',
    placeId: 'bari-it',
    location: { lat: 41.1142, lng: 16.8728 },
  },
  {
    type: 'city',
    name: 'Milan',
    countryCode: 'IT',
    placeId: 'milan-it',
    location: { lat: 45.47, lng: 9.205 },
  },
  {
    type: 'city',
    name: 'Catania',
    countryCode: 'IT',
    placeId: 'catania-it',
    location: { lat: 37.5, lng: 15.08 },
  },
  {
    type: 'city',
    name: 'Valletta',
    countryCode: 'MT',
    placeId: 'valletta-mt',
    location: { lat: 35.8997, lng: 14.5147 },
  },
  {
    type: 'city',
    name: 'Gdańsk',
    countryCode: 'PL',
    placeId: 'gdansk-pl',
    location: { lat: 54.36, lng: 18.64 },
  },
  {
    type: 'city',
    name: 'Warsaw',
    countryCode: 'PL',
    placeId: 'warsaw-pl',
    location: { lat: 52.25, lng: 21 },
  },
  {
    type: 'city',
    name: 'Wrocław',
    countryCode: 'PL',
    placeId: 'wroclaw-pl',
    location: { lat: 51.1104, lng: 17.03 },
  },
  {
    type: 'city',
    name: 'Tallinn',
    countryCode: 'EE',
    placeId: 'tallinn-ee',
    location: { lat: 59.4339, lng: 24.728 },
  },
  {
    type: 'city',
    name: 'Riga',
    countryCode: 'LV',
    placeId: 'riga-lv',
    location: { lat: 56.95, lng: 24.1 },
  },
  {
    type: 'city',
    name: 'Budapest',
    countryCode: 'HU',
    placeId: 'budapest-hu',
    location: { lat: 47.5, lng: 19.0833 },
  },
  {
    type: 'city',
    name: 'Prague',
    countryCode: 'CZ',
    placeId: 'prague-cz',
    location: { lat: 50.0833, lng: 14.466 },
  },
  {
    type: 'city',
    name: 'Brno',
    countryCode: 'CZ',
    placeId: 'brno-cz',
    location: { lat: 49.2004, lng: 16.61 },
  },
  {
    type: 'city',
    name: 'Sofia',
    countryCode: 'BG',
    placeId: 'sofia-bg',
    location: { lat: 42.6833, lng: 23.3167 },
  },
  {
    type: 'city',
    name: 'Bucharest',
    countryCode: 'RO',
    placeId: 'bucharest-ro',
    location: { lat: 44.4334, lng: 26.0999 },
  },
  {
    type: 'city',
    name: 'Belgrade',
    countryCode: 'RS',
    placeId: 'belgrade-rs',
    location: { lat: 44.8186, lng: 20.468 },
  },
  {
    type: 'city',
    name: 'Zagreb',
    countryCode: 'HR',
    placeId: 'zagreb-hr',
    location: { lat: 45.8, lng: 16 },
  },
  {
    type: 'city',
    name: 'Athens',
    countryCode: 'GR',
    placeId: 'athens-gr',
    location: { lat: 37.9833, lng: 23.7333 },
  },
  {
    type: 'city',
    name: 'Thessaloníki',
    countryCode: 'GR',
    placeId: 'thessaloniki-gr',
    location: { lat: 40.6961, lng: 22.885 },
  },
  {
    type: 'city',
    name: 'Istanbul',
    countryCode: 'TR',
    placeId: 'istanbul-tr',
    location: { lat: 41.105, lng: 29.01 },
  },
  {
    type: 'city',
    name: 'Mersin',
    countryCode: 'TR',
    placeId: 'mersin-tr',
    location: { lat: 36.8004, lng: 34.6128 },
  },
  {
    type: 'city',
    name: 'Tel Aviv-Yafo',
    countryCode: 'IL',
    placeId: 'tel-aviv-il',
    location: { lat: 32.08, lng: 34.77 },
  },
  {
    type: 'city',
    name: 'Beirut',
    countryCode: 'LB',
    placeId: 'beirut-lb',
    location: { lat: 33.872, lng: 35.5097 },
  },
  {
    type: 'city',
    name: 'Haifa',
    countryCode: 'IL',
    placeId: 'haifa-il',
    location: { lat: 32.8204, lng: 34.98 },
  },
  {
    type: 'city',
    name: 'Alexandria',
    countryCode: 'EG',
    placeId: 'alexandria-eg',
    location: { lat: 31.2, lng: 29.95 },
  },
  {
    type: 'city',
    name: 'Port Said',
    countryCode: 'EG',
    placeId: 'port-said-eg',
    location: { lat: 31.26, lng: 32.29 },
  },
  {
    type: 'city',
    name: 'Cairo',
    countryCode: 'EG',
    placeId: 'cairo-eg',
    location: { lat: 30.05, lng: 31.25 },
  },
  {
    type: 'city',
    name: 'Tunis',
    countryCode: 'TN',
    placeId: 'tunis-tn',
    location: { lat: 36.8028, lng: 10.1797 },
  },
  {
    type: 'city',
    name: 'Algiers',
    countryCode: 'DZ',
    placeId: 'algiers-dz',
    location: { lat: 36.7631, lng: 3.0506 },
  },
  {
    type: 'city',
    name: 'Tripoli',
    countryCode: 'LY',
    placeId: 'tripoli-ly',
    location: { lat: 32.8925, lng: 13.18 },
  },
  {
    type: 'city',
    name: 'Mumbai',
    countryCode: 'IN',
    placeId: 'mumbai-in',
    location: { lat: 19.017, lng: 72.857 },
  },
  {
    type: 'city',
    name: 'Kolkata',
    countryCode: 'IN',
    placeId: 'kolkata-in',
    location: { lat: 22.495, lng: 88.3247 },
  },
  {
    type: 'city',
    name: 'Delhi',
    countryCode: 'IN',
    placeId: 'delhi-in',
    location: { lat: 28.67, lng: 77.23 },
  },
  {
    type: 'city',
    name: 'Chennai',
    countryCode: 'IN',
    placeId: 'chennai-in',
    location: { lat: 13.09, lng: 80.28 },
  },
  {
    type: 'city',
    name: 'Colombo',
    countryCode: 'LK',
    placeId: 'colombo-lk',
    location: { lat: 6.932, lng: 79.8578 },
  },
  {
    type: 'city',
    name: 'Karachi',
    countryCode: 'PK',
    placeId: 'karachi-pk',
    location: { lat: 24.87, lng: 66.99 },
  },
  {
    type: 'city',
    name: 'Faisalabad',
    countryCode: 'PK',
    placeId: 'faisalabad-pk',
    location: { lat: 31.41, lng: 73.11 },
  },
  {
    type: 'city',
    name: 'Chittagong',
    countryCode: 'BD',
    placeId: 'chittagong-bd',
    location: { lat: 22.33, lng: 91.8 },
  },
  {
    type: 'city',
    name: 'Dhaka',
    countryCode: 'BD',
    placeId: 'dhaka-bd',
    location: { lat: 23.7231, lng: 90.4086 },
  },
  {
    type: 'city',
    name: 'Dubai',
    countryCode: 'AE',
    placeId: 'dubai-ae',
    location: { lat: 25.23, lng: 55.28 },
  },
  {
    type: 'city',
    name: 'Abu Dhabi',
    countryCode: 'AE',
    placeId: 'abu-dhabi-ae',
    location: { lat: 24.4667, lng: 54.3666 },
  },
  {
    type: 'city',
    name: 'Şalālah',
    countryCode: 'OM',
    placeId: 'salalah-om',
    location: { lat: 17.0255, lng: 54.0852 },
  },
  {
    type: 'city',
    name: 'Al ‘Aqabah',
    countryCode: 'JO',
    placeId: 'al-aqabah-jo',
    location: { lat: 29.527, lng: 35.0777 },
  },
  {
    type: 'city',
    name: 'Mecca',
    countryCode: 'SA',
    placeId: 'mecca-sa',
    location: { lat: 21.43, lng: 39.82 },
  },
  {
    type: 'city',
    name: 'Jeddah',
    countryCode: 'SA',
    placeId: 'jeddah-sa',
    location: { lat: 21.5169, lng: 39.2192 },
  },
  {
    type: 'city',
    name: 'Aden',
    countryCode: 'YE',
    placeId: 'aden-ye',
    location: { lat: 12.7797, lng: 45.0095 },
  },
  {
    type: 'city',
    name: 'Casablanca',
    countryCode: 'MA',
    placeId: 'casablanca-ma',
    location: { lat: 33.6, lng: -7.6164 },
  },
  {
    type: 'city',
    name: 'Tangier',
    countryCode: 'MA',
    placeId: 'tangier-ma',
    location: { lat: 35.7473, lng: -5.8327 },
  },
  {
    type: 'city',
    name: 'Lagos',
    countryCode: 'NG',
    placeId: 'lagos-ng',
    location: { lat: 6.4433, lng: 3.3915 },
  },
  {
    type: 'city',
    name: 'Addis Ababa',
    countryCode: 'ET',
    placeId: 'addis-ababa-et',
    location: { lat: 9.0333, lng: 38.7 },
  },
  {
    type: 'city',
    name: 'Durban',
    countryCode: 'ZA',
    placeId: 'durban-za',
    location: { lat: -29.865, lng: 30.98 },
  },
  {
    type: 'city',
    name: 'Johannesburg',
    countryCode: 'ZA',
    placeId: 'johannesburg-za',
    location: { lat: -26.17, lng: 28.03 },
  },
  {
    type: 'city',
    name: 'Cape Town',
    countryCode: 'ZA',
    placeId: 'cape-town-za',
    location: { lat: -33.92, lng: 18.435 },
  },
  {
    type: 'city',
    name: 'Nairobi',
    countryCode: 'KE',
    placeId: 'nairobi-ke',
    location: { lat: -1.2833, lng: 36.8167 },
  },
  {
    type: 'city',
    name: 'Douala',
    countryCode: 'CM',
    placeId: 'douala-cm',
    location: { lat: 4.0604, lng: 9.71 },
  },
  {
    type: 'city',
    name: 'Mombasa',
    countryCode: 'KE',
    placeId: 'mombasa-ke',
    location: { lat: -4.04, lng: 39.6899 },
  },
  {
    type: 'city',
    name: 'Dakar',
    countryCode: 'SN',
    placeId: 'dakar-sn',
    location: { lat: 14.7158, lng: -17.4731 },
  },
  {
    type: 'city',
    name: 'Luanda',
    countryCode: 'AO',
    placeId: 'luanda-ao',
    location: { lat: -8.8383, lng: 13.2344 },
  },
  {
    type: 'city',
    name: 'São Paulo',
    countryCode: 'BR',
    placeId: 'sao-paulo-br',
    location: { lat: -23.5587, lng: -46.625 },
  },
  {
    type: 'city',
    name: 'Salvador',
    countryCode: 'BR',
    placeId: 'salvador-br',
    location: { lat: -12.97, lng: -38.48 },
  },
  {
    type: 'city',
    name: 'Cartagena',
    countryCode: 'CO',
    placeId: 'cartagena-co',
    location: { lat: 10.3997, lng: -75.5144 },
  },
  {
    type: 'city',
    name: 'Panama City',
    countryCode: 'US',
    placeId: 'panama-city-us',
    location: { lat: 30.1995, lng: -85.6003 },
  },
  {
    type: 'city',
    name: 'Kingston',
    countryCode: 'JM',
    placeId: 'kingston-jm',
    location: { lat: 17.9771, lng: -76.7674 },
  },
  {
    type: 'city',
    name: 'Freeport City',
    countryCode: 'BS',
    placeId: 'freeport-city-bs',
    location: { lat: 26.5333, lng: -78.7 },
  },
  {
    type: 'city',
    name: 'Santiago',
    countryCode: 'CL',
    placeId: 'santiago-cl',
    location: { lat: -33.45, lng: -70.667 },
  },
  {
    type: 'city',
    name: 'Managua',
    countryCode: 'NI',
    placeId: 'managua-ni',
    location: { lat: 12.153, lng: -86.2685 },
  },
  {
    type: 'city',
    name: 'Buenos Aires',
    countryCode: 'AR',
    placeId: 'buenos-aires-ar',
    location: { lat: -34.6025, lng: -58.3975 },
  },
  {
    type: 'city',
    name: 'Montevideo',
    countryCode: 'UY',
    placeId: 'montevideo-uy',
    location: { lat: -34.858, lng: -56.1711 },
  },
  {
    type: 'city',
    name: 'Lima',
    countryCode: 'PE',
    placeId: 'lima-pe',
    location: { lat: -12.048, lng: -77.0501 },
  },
  {
    type: 'city',
    name: 'Quito',
    countryCode: 'EC',
    placeId: 'quito-ec',
    location: { lat: -0.215, lng: -78.5001 },
  },
  {
    type: 'city',
    name: 'La Paz',
    countryCode: 'BO',
    placeId: 'la-paz-bo',
    location: { lat: -16.498, lng: -68.15 },
  },
  {
    type: 'city',
    name: 'Guayaquil',
    countryCode: 'EC',
    placeId: 'guayaquil-ec',
    location: { lat: -2.22, lng: -79.92 },
  },
  {
    type: 'city',
    name: 'San José',
    countryCode: 'CR',
    placeId: 'san-jose-cr',
    location: { lat: 9.935, lng: -84.0841 },
  },
  {
    type: 'city',
    name: 'Havana',
    countryCode: 'CU',
    placeId: 'havana-cu',
    location: { lat: 23.132, lng: -82.3642 },
  },
  {
    type: 'country',
    name: 'United Arab Emirates',
    countryCode: 'AE',
    placeId: 'united-arab-emirates',
    location: { lat: 23.424076, lng: 53.847818 },
  },
  {
    type: 'country',
    name: 'Angola',
    countryCode: 'AO',
    placeId: 'angola',
    location: { lat: -11.202692, lng: 17.873887 },
  },
  {
    type: 'country',
    name: 'Argentina',
    countryCode: 'AR',
    placeId: 'argentina',
    location: { lat: -38.416097, lng: -63.616672 },
  },
  {
    type: 'country',
    name: 'Austria',
    countryCode: 'AT',
    placeId: 'austria',
    location: { lat: 47.516231, lng: 14.550072 },
  },
  {
    type: 'country',
    name: 'Australia',
    countryCode: 'AU',
    placeId: 'australia',
    location: { lat: -25.274398, lng: 133.775136 },
  },
  {
    type: 'country',
    name: 'Bangladesh',
    countryCode: 'BD',
    placeId: 'bangladesh',
    location: { lat: 23.684994, lng: 90.356331 },
  },
  {
    type: 'country',
    name: 'Belgium',
    countryCode: 'BE',
    placeId: 'belgium',
    location: { lat: 50.503887, lng: 4.469936 },
  },
  {
    type: 'country',
    name: 'Bulgaria',
    countryCode: 'BG',
    placeId: 'bulgaria',
    location: { lat: 42.733883, lng: 25.48583 },
  },
  {
    type: 'country',
    name: 'Bahrain',
    countryCode: 'BH',
    placeId: 'bahrain',
    location: { lat: 25.930414, lng: 50.637772 },
  },
  {
    type: 'country',
    name: 'Bolivia',
    countryCode: 'BO',
    placeId: 'bolivia',
    location: { lat: -16.290154, lng: -63.588653 },
  },
  {
    type: 'country',
    name: 'Brazil',
    countryCode: 'BR',
    placeId: 'brazil',
    location: { lat: -14.235004, lng: -51.92528 },
  },
  {
    type: 'country',
    name: 'Canada',
    countryCode: 'CA',
    placeId: 'canada',
    location: { lat: 56.130366, lng: -106.346771 },
  },
  {
    type: 'country',
    name: 'Switzerland',
    countryCode: 'CH',
    placeId: 'switzerland',
    location: { lat: 46.818188, lng: 8.227512 },
  },
  {
    type: 'country',
    name: 'Chile',
    countryCode: 'CL',
    placeId: 'chile',
    location: { lat: -35.675147, lng: -71.542969 },
  },
  {
    type: 'country',
    name: 'Cameroon',
    countryCode: 'CM',
    placeId: 'cameroon',
    location: { lat: 7.369722, lng: 12.354722 },
  },
  {
    type: 'country',
    name: 'China',
    countryCode: 'CN',
    placeId: 'china',
    location: { lat: 35.86166, lng: 104.195397 },
  },
  {
    type: 'country',
    name: 'Colombia',
    countryCode: 'CO',
    placeId: 'colombia',
    location: { lat: 4.570868, lng: -74.297333 },
  },
  {
    type: 'country',
    name: 'Costa Rica',
    countryCode: 'CR',
    placeId: 'costa-rica',
    location: { lat: 9.748917, lng: -83.753428 },
  },
  {
    type: 'country',
    name: 'Cuba',
    countryCode: 'CU',
    placeId: 'cuba',
    location: { lat: 21.521757, lng: -77.781167 },
  },
  {
    type: 'country',
    name: 'Czech Republic',
    countryCode: 'CZ',
    placeId: 'czech-republic',
    location: { lat: 49.817492, lng: 15.472962 },
  },
  {
    type: 'country',
    name: 'Germany',
    countryCode: 'DE',
    placeId: 'germany',
    location: { lat: 51.165691, lng: 10.451526 },
  },
  {
    type: 'country',
    name: 'Denmark',
    countryCode: 'DK',
    placeId: 'denmark',
    location: { lat: 56.26392, lng: 9.501785 },
  },
  {
    type: 'country',
    name: 'Algeria',
    countryCode: 'DZ',
    placeId: 'algeria',
    location: { lat: 28.033886, lng: 1.659626 },
  },
  {
    type: 'country',
    name: 'Ecuador',
    countryCode: 'EC',
    placeId: 'ecuador',
    location: { lat: -1.831239, lng: -78.183406 },
  },
  {
    type: 'country',
    name: 'Estonia',
    countryCode: 'EE',
    placeId: 'estonia',
    location: { lat: 58.595272, lng: 25.013607 },
  },
  {
    type: 'country',
    name: 'Egypt',
    countryCode: 'EG',
    placeId: 'egypt',
    location: { lat: 26.820553, lng: 30.802498 },
  },
  {
    type: 'country',
    name: 'Spain',
    countryCode: 'ES',
    placeId: 'spain',
    location: { lat: 40.463667, lng: -3.74922 },
  },
  {
    type: 'country',
    name: 'Ethiopia',
    countryCode: 'ET',
    placeId: 'ethiopia',
    location: { lat: 9.145, lng: 40.489673 },
  },
  {
    type: 'country',
    name: 'Finland',
    countryCode: 'FI',
    placeId: 'finland',
    location: { lat: 61.92411, lng: 25.748151 },
  },
  {
    type: 'country',
    name: 'France',
    countryCode: 'FR',
    placeId: 'france',
    location: { lat: 46.227638, lng: 2.213749 },
  },
  {
    type: 'country',
    name: 'United Kingdom',
    countryCode: 'GB',
    placeId: 'united-kingdom',
    location: { lat: 55.378051, lng: -3.435973 },
  },
  {
    type: 'country',
    name: 'Georgia',
    countryCode: 'GE',
    placeId: 'georgia',
    location: { lat: 42.315407, lng: 43.356892 },
  },
  {
    type: 'country',
    name: 'Ghana',
    countryCode: 'GH',
    placeId: 'ghana',
    location: { lat: 7.946527, lng: -1.023194 },
  },
  {
    type: 'country',
    name: 'Greece',
    countryCode: 'GR',
    placeId: 'greece',
    location: { lat: 39.074208, lng: 21.824312 },
  },
  {
    type: 'country',
    name: 'Guatemala',
    countryCode: 'GT',
    placeId: 'guatemala',
    location: { lat: 15.783471, lng: -90.230759 },
  },
  {
    type: 'country',
    name: 'Croatia',
    countryCode: 'HR',
    placeId: 'croatia',
    location: { lat: 45.1, lng: 15.2 },
  },
  {
    type: 'country',
    name: 'Hungary',
    countryCode: 'HU',
    placeId: 'hungary',
    location: { lat: 47.162494, lng: 19.503304 },
  },
  {
    type: 'country',
    name: 'Indonesia',
    countryCode: 'ID',
    placeId: 'indonesia',
    location: { lat: -0.789275, lng: 113.921327 },
  },
  {
    type: 'country',
    name: 'Ireland',
    countryCode: 'IE',
    placeId: 'ireland',
    location: { lat: 53.41291, lng: -8.24389 },
  },
  {
    type: 'country',
    name: 'Israel',
    countryCode: 'IL',
    placeId: 'israel',
    location: { lat: 31.046051, lng: 34.851612 },
  },
  {
    type: 'country',
    name: 'India',
    countryCode: 'IN',
    placeId: 'india',
    location: { lat: 20.593684, lng: 78.96288 },
  },
  {
    type: 'country',
    name: 'Iran',
    countryCode: 'IR',
    placeId: 'iran',
    location: { lat: 32.427908, lng: 53.688046 },
  },
  {
    type: 'country',
    name: 'Iceland',
    countryCode: 'IS',
    placeId: 'iceland',
    location: { lat: 64.963051, lng: -19.020835 },
  },
  {
    type: 'country',
    name: 'Italy',
    countryCode: 'IT',
    placeId: 'italy',
    location: { lat: 41.87194, lng: 12.56738 },
  },
  {
    type: 'country',
    name: 'Jamaica',
    countryCode: 'JM',
    placeId: 'jamaica',
    location: { lat: 18.109581, lng: -77.297508 },
  },
  {
    type: 'country',
    name: 'Jordan',
    countryCode: 'JO',
    placeId: 'jordan',
    location: { lat: 30.585164, lng: 36.238414 },
  },
  {
    type: 'country',
    name: 'Japan',
    countryCode: 'JP',
    placeId: 'japan',
    location: { lat: 36.204824, lng: 138.252924 },
  },
  {
    type: 'country',
    name: 'Kenya',
    countryCode: 'KE',
    placeId: 'kenya',
    location: { lat: -0.023559, lng: 37.906193 },
  },
  {
    type: 'country',
    name: 'Cambodia',
    countryCode: 'KH',
    placeId: 'cambodia',
    location: { lat: 12.565679, lng: 104.990963 },
  },
  {
    type: 'country',
    name: 'South Korea',
    countryCode: 'KR',
    placeId: 'south-korea',
    location: { lat: 35.907757, lng: 127.766922 },
  },
  {
    type: 'country',
    name: 'Kuwait',
    countryCode: 'KW',
    placeId: 'kuwait',
    location: { lat: 29.31166, lng: 47.481766 },
  },
  {
    type: 'country',
    name: 'Laos',
    countryCode: 'LA',
    placeId: 'laos',
    location: { lat: 19.85627, lng: 102.495496 },
  },
  {
    type: 'country',
    name: 'Lebanon',
    countryCode: 'LB',
    placeId: 'lebanon',
    location: { lat: 33.854721, lng: 35.862285 },
  },
  {
    type: 'country',
    name: 'Sri Lanka',
    countryCode: 'LK',
    placeId: 'sri-lanka',
    location: { lat: 7.873054, lng: 80.771797 },
  },
  {
    type: 'country',
    name: 'Luxembourg',
    countryCode: 'LU',
    placeId: 'luxembourg',
    location: { lat: 49.815273, lng: 6.129583 },
  },
  {
    type: 'country',
    name: 'Lithuania',
    countryCode: 'LT',
    placeId: 'lithuania',
    location: { lat: 55.169438, lng: 23.881275 },
  },
  {
    type: 'country',
    name: 'Latvia',
    countryCode: 'LV',
    placeId: 'latvia',
    location: { lat: 56.879635, lng: 24.603189 },
  },
  {
    type: 'country',
    name: 'Libya',
    countryCode: 'LY',
    placeId: 'libya',
    location: { lat: 26.3351, lng: 17.228331 },
  },
  {
    type: 'country',
    name: 'Morocco',
    countryCode: 'MA',
    placeId: 'morocco',
    location: { lat: 31.791702, lng: -7.09262 },
  },
  {
    type: 'country',
    name: 'Malta',
    countryCode: 'MT',
    placeId: 'malta',
    location: { lat: 35.937496, lng: 14.375416 },
  },
  {
    type: 'country',
    name: 'Mexico',
    countryCode: 'MX',
    placeId: 'mexico',
    location: { lat: 23.634501, lng: -102.552784 },
  },
  {
    type: 'country',
    name: 'Malaysia',
    countryCode: 'MY',
    placeId: 'malaysia',
    location: { lat: 4.210484, lng: 101.975766 },
  },
  {
    type: 'country',
    name: 'Mozambique',
    countryCode: 'MZ',
    placeId: 'mozambique',
    location: { lat: -18.665695, lng: 35.529562 },
  },
  {
    type: 'country',
    name: 'Namibia',
    countryCode: 'NA',
    placeId: 'namibia',
    location: { lat: -22.95764, lng: 18.49041 },
  },
  {
    type: 'country',
    name: 'Nigeria',
    countryCode: 'NG',
    placeId: 'nigeria',
    location: { lat: 9.081999, lng: 8.675277 },
  },
  {
    type: 'country',
    name: 'Nicaragua',
    countryCode: 'NI',
    placeId: 'nicaragua',
    location: { lat: 12.865416, lng: -85.207229 },
  },
  {
    type: 'country',
    name: 'Netherlands',
    countryCode: 'NL',
    placeId: 'netherlands',
    location: { lat: 52.132633, lng: 5.291266 },
  },
  {
    type: 'country',
    name: 'Norway',
    countryCode: 'NO',
    placeId: 'norway',
    location: { lat: 60.472024, lng: 8.468946 },
  },
  {
    type: 'country',
    name: 'New Zealand',
    countryCode: 'NZ',
    placeId: 'new-zealand',
    location: { lat: -40.900557, lng: 174.885971 },
  },
  {
    type: 'country',
    name: 'Oman',
    countryCode: 'OM',
    placeId: 'oman',
    location: { lat: 21.512583, lng: 55.923255 },
  },
  {
    type: 'country',
    name: 'Panama',
    countryCode: 'PA',
    placeId: 'panama',
    location: { lat: 8.537981, lng: -80.782127 },
  },
  {
    type: 'country',
    name: 'Peru',
    countryCode: 'PE',
    placeId: 'peru',
    location: { lat: -9.189967, lng: -75.015152 },
  },
  {
    type: 'country',
    name: 'Philippines',
    countryCode: 'PH',
    placeId: 'philippines',
    location: { lat: 12.879721, lng: 121.774017 },
  },
  {
    type: 'country',
    name: 'Pakistan',
    countryCode: 'PK',
    placeId: 'pakistan',
    location: { lat: 30.375321, lng: 69.345116 },
  },
  {
    type: 'country',
    name: 'Poland',
    countryCode: 'PL',
    placeId: 'poland',
    location: { lat: 51.919438, lng: 19.145136 },
  },
  {
    type: 'country',
    name: 'Portugal',
    countryCode: 'PT',
    placeId: 'portugal',
    location: { lat: 39.399872, lng: -8.224454 },
  },
  {
    type: 'country',
    name: 'Qatar',
    countryCode: 'QA',
    placeId: 'qatar',
    location: { lat: 25.354826, lng: 51.183884 },
  },
  {
    type: 'country',
    name: 'Romania',
    countryCode: 'RO',
    placeId: 'romania',
    location: { lat: 45.943161, lng: 24.96676 },
  },
  {
    type: 'country',
    name: 'Serbia',
    countryCode: 'RS',
    placeId: 'serbia',
    location: { lat: 44.016521, lng: 21.005859 },
  },
  {
    type: 'country',
    name: 'Russia',
    countryCode: 'RU',
    placeId: 'russia',
    location: { lat: 61.52401, lng: 105.318756 },
  },
  {
    type: 'country',
    name: 'Saudi Arabia',
    countryCode: 'SA',
    placeId: 'saudi-arabia',
    location: { lat: 23.885942, lng: 45.079162 },
  },
  {
    type: 'country',
    name: 'Sweden',
    countryCode: 'SE',
    placeId: 'sweden',
    location: { lat: 60.128161, lng: 18.643501 },
  },
  {
    type: 'country',
    name: 'Slovenia',
    countryCode: 'SI',
    placeId: 'slovenia',
    location: { lat: 46.151241, lng: 14.995463 },
  },
  {
    type: 'country',
    name: 'Singapore',
    countryCode: 'SG',
    placeId: 'singapore',
    location: { lat: 1.352083, lng: 103.819836 },
  },
  {
    type: 'country',
    name: 'Slovakia',
    countryCode: 'SK',
    placeId: 'slovakia',
    location: { lat: 48.669026, lng: 19.699024 },
  },
  {
    type: 'country',
    name: 'Thailand',
    countryCode: 'TH',
    placeId: 'thailand',
    location: { lat: 15.870032, lng: 100.992541 },
  },
  {
    type: 'country',
    name: 'Tunisia',
    countryCode: 'TN',
    placeId: 'tunisia',
    location: { lat: 33.886917, lng: 9.537499 },
  },
  {
    type: 'country',
    name: 'Turkey',
    countryCode: 'TR',
    placeId: 'turkey',
    location: { lat: 38.963745, lng: 35.243322 },
  },
  {
    type: 'country',
    name: 'Taiwan',
    countryCode: 'TW',
    placeId: 'taiwan',
    location: { lat: 23.69781, lng: 120.960515 },
  },
  {
    type: 'country',
    name: 'Tanzania',
    countryCode: 'TZ',
    placeId: 'tanzania',
    location: { lat: -6.369028, lng: 34.888822 },
  },
  {
    type: 'country',
    name: 'United States',
    countryCode: 'US',
    placeId: 'united-states',
    location: { lat: 37.09024, lng: -95.712891 },
  },
  {
    type: 'country',
    name: 'Uruguay',
    countryCode: 'UY',
    placeId: 'uruguay',
    location: { lat: -32.522779, lng: -55.765835 },
  },
  {
    type: 'country',
    name: 'Venezuela',
    countryCode: 'VE',
    placeId: 'venezuela',
    location: { lat: 6.42375, lng: -66.58973 },
  },
  {
    type: 'country',
    name: 'Vietnam',
    countryCode: 'VN',
    placeId: 'vietnam',
    location: { lat: 14.058324, lng: 108.277199 },
  },
  {
    type: 'country',
    name: 'Yemen',
    countryCode: 'YE',
    placeId: 'yemen',
    location: { lat: 15.552727, lng: 48.516388 },
  },
  {
    type: 'country',
    name: 'South Africa',
    countryCode: 'ZA',
    placeId: 'south-africa',
    location: { lat: -30.559482, lng: 22.937506 },
  },
];
