/**
 * Custom hook to identify if a user just logged in and log some identity data
 */

import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { NEXT_AUTH_STATUSES } from '@/lib/constants';
import { trackUser } from '@/utils/tracking';

export const useIdentifyUser = () => {
  const [sessionInitialised, setSessionInitialised] = useState(false);
  const { status, data: session } = useSession();

  useEffect(() => {
    if (status === NEXT_AUTH_STATUSES.AUTHENTICATED && !sessionInitialised) {
      setSessionInitialised(true);
      trackUser.setUserIdentity(session!.user.id, { email: session!.user.email });
    }
  }, [status, session]);
};
