import { ReactNode } from 'react';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/styles';

interface Props {
  iconLeft?: ReactNode | string;
  iconRight?: ReactNode | string;
  children: string | ReactNode[] | ReactNode;
  truncateText?: boolean;
  className?: string;
}

const ButtonContentLayout = ({ iconLeft, iconRight, children, truncateText, className }: Props) => {
  return (
    <span className={cn('flex items-center gap-2', className)}>
      {iconLeft && (
        <span>
          {typeof iconLeft === 'string' ? <Icon name={iconLeft} size="base" color="currentColor" /> : iconLeft}
        </span>
      )}
      <span
        className={cn('w-full', {
          'mt-1 -translate-y-px truncate leading-ultraTight': truncateText,
        })}
      >
        {children}
      </span>
      {iconRight && (
        <span>
          {typeof iconRight === 'string' ? <Icon name={iconRight} size="base" color="currentColor" /> : iconRight}
        </span>
      )}
    </span>
  );
};

export { ButtonContentLayout };
