import { INPUT_HEIGHT, INPUT_HEIGHT_SMALL } from '@/lib/constants';
import { colors, fontSizes, space } from '@/theme/constants';

const selectBoxTheme = {
  selectBox: {
    light: {
      input: {
        color: colors.text.primary,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grey[300]}`,

        '&::placeholder': {
          color: colors.text.primary,
        },

        '&:not[disabled]:hover': {
          borderColor: colors.grey[400],
        },

        '&[disabled]': {
          opacity: 0.6,
          cursor: 'default',

          '+ .select-box-fake-input-value': {
            opacity: 0.6,
          },
        },

        svg: {
          fill: colors.text.primary,
        },
      },
      '&.has-error': {
        'input, textarea': {
          borderColor: colors.forms.states.error,
          boxShadow: `0 0 0 1px ${colors.forms.states.error}`,
          color: colors.forms.states.error,

          '&:hover:not(:focus)': {
            borderColor: colors.forms.states.error,
          },

          '&::placeholder': {
            color: colors.forms.states.error,
          },
        },

        '.error': {
          color: colors.forms.states.error,
        },
      },
      '.input-wrapper': {
        svg: {
          fill: colors.text.primary,
        },
      },

      label: {
        marginBottom: space.xs,
      },
    },
    dark: {
      input: {
        color: colors.grey[200],
        backgroundColor: '#3F4143',
        border: `1px solid ${colors.grey[900]}`,

        '&::placeholder': {
          color: colors.grey[200],
        },

        '&:not[disabled]:hover': {
          borderColor: colors.grey[700],
        },

        '&[disabled]': {
          opacity: 0.6,
          cursor: 'default',

          '+ .select-box-fake-input-value': {
            opacity: 0.6,
          },
        },

        svg: {
          fill: colors.grey[200],
        },
      },
      '.input-wrapper': {
        svg: {
          fill: colors.grey[200],
        },
      },
      '.select-box-fake-input-value': {
        color: colors.grey[200],
      },

      label: {
        marginBottom: space.xs,
      },
    },
  },
  selectBoxDropdown: {
    medium: {
      padding: `${space.sm}`,
      '.select-box-item': {
        padding: `${space.xs} ${space.sm}`,
        minHeight: space.lg,
        fontSize: fontSizes.baseSm,
      },
    },
    small: {
      padding: `${space.xs}`,
      '.select-box-item': {
        padding: `${space.xs} ${space.sm}`,
        fontSize: fontSizes.baseSm,
      },
    },
  },
  selectBoxSizes: {
    medium: {
      input: {
        padding: `0 ${space.lg} 0 ${space.base}`,
        height: INPUT_HEIGHT,
      },
      '.select-box-fake-input-value': {
        fontSize: fontSizes.baseSm,
        left: space.base,
      },
      '.select-box-toggle-button': {
        width: space.lg,
      },
    },
    small: {
      input: {
        padding: `0 ${space.base} 0 ${space[3]}`,
        height: INPUT_HEIGHT_SMALL,
        fontSize: fontSizes.baseSm,
      },
      '.select-box-fake-input-value': {
        fontSize: fontSizes.baseSm,
        left: space[3],
      },
      '.select-box-toggle-button': {
        width: space.md,
      },
    },
  },
};

export { selectBoxTheme };
