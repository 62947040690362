const lineHeights = {
  none: '1',
  ultraTight: '1.05',
  tight: '1.2',
  snug: '1.375',
  normal: '1.5',
  relaxed: '1.625',
  loose: '2',
};

export { lineHeights };
