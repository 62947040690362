import { Kalam } from 'next/font/google';
import React from 'react';

import { CASUAL_FONT_CSS_VARIABLE } from '../../../tailwind.config';

const kalam = Kalam({ weight: '400', subsets: ['latin'], display: 'swap' });

export const Fonts = () => {
  return (
    // eslint-disable-next-line react/no-unknown-property
    <style jsx global>{`
      :root {
        ${CASUAL_FONT_CSS_VARIABLE}: ${kalam.style.fontFamily};
      }
    `}</style>
  );
};
