import React from 'react';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  as?: React.ElementType;
  tabIndex?: number | null;
}

const VisuallyHidden = ({ children, as = 'span', tabIndex = -1, ...props }: Props) =>
  React.createElement(as, { className: 'sr-only', tabIndex, ...props }, children);

export { VisuallyHidden };
