import { compact, isEmpty } from 'lodash';

import { ID_CANONICAL, ID_IATA, ID_IMO, ID_LOCODE, ID_NODEID, ID_SCAC } from '@/lib/constants';
import { IdentifierApiInterface } from '@/types/api-types';

export const getPreferredIdentifier = (
  identifiers?: IdentifierApiInterface[] | null,
  excludeCanonicals = false,
): IdentifierApiInterface | null => {
  if (!identifiers) return null;

  const filteredIdentifiers = compact(identifiers).filter((id) => {
    // Exlude Canonicals if required
    if (excludeCanonicals && id.type === ID_CANONICAL) return false;
    // else keep everything that has a value
    return id.value;
  });

  // Early out if array is now empty
  if (isEmpty(identifiers)) return null;

  // find iata (AirPorts / Airlines)
  const iata = filteredIdentifiers.find((id) => id.type === ID_IATA);

  if (iata && !isEmpty(iata)) return iata;
  // else, find locode (SeaPorts)
  const locode = filteredIdentifiers.find((id) => id.type === ID_LOCODE);
  if (locode && !isEmpty(locode)) return locode;
  // else, find scac (Ocean, Road)
  const scac = filteredIdentifiers.find((id) => id.type === ID_SCAC);
  if (scac && !isEmpty(scac)) return scac;
  // else, find imo (Ocean carriers)
  const imo = filteredIdentifiers.find((id) => id.type === ID_IMO);
  if (imo && !isEmpty(imo)) return imo;
  // else, find canonical (Ocean)
  const canonical = filteredIdentifiers.find((id) => id.type === ID_CANONICAL);
  if (canonical && !isEmpty(canonical)) return canonical;

  // ELSE just get first Identifier
  const firstIdentifier = filteredIdentifiers.length ? filteredIdentifiers[0] : null;
  // Handle NodeId returning as identifier
  return firstIdentifier?.type !== ID_NODEID ? firstIdentifier : null;
};

export const formatPreferredIdentifier = (identifier?: IdentifierApiInterface | null): string => {
  if (!identifier) return '';
  return Object.values(identifier).join(' ');
};
