import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';

import { RouteScheduleInterface, ScheduleQueryInterface } from '@/types';

// Keeps track of IDs (so we can reset all later)
export const scheduleIdsState = atom<string[]>({
  key: 'scheduleIdsState',
  default: [],
});

// Atom Family for actual schedules results
export const scheduleFamilyState = atomFamily<RouteScheduleInterface[], string>({
  key: 'scheduleFamilyState',
  default: [],
});

// Atom Family for the schedules query info
// (this is separated from the above Atom to help with rerenders when the below changes)
export const scheduleQueryFamilyState = atomFamily<ScheduleQueryInterface, string>({
  key: 'scheduleQueryFamilyState',
  default: {
    dateRange: undefined,
    isSearching: false,
    hasSearched: false,
  },
});

// Selector that abstracts scheduleQuery AtomFamily
export const getScheduleQueryByIdState = selectorFamily<ScheduleQueryInterface, string>({
  key: 'getScheduleQueryByIdState',
  get:
    (id: string) =>
    ({ get }) => {
      // Query the schedule by ID
      const schedules = get(scheduleQueryFamilyState(id));
      return schedules;
    },
  set:
    (id) =>
    ({ set, reset }, data) => {
      // Update Schedule by ID + add ID to IDs list
      if (data instanceof DefaultValue) {
        // DefaultValue means reset context
        reset(scheduleQueryFamilyState(id));
        return;
      }
      // Update the SchedulesQuery Atom
      set(scheduleQueryFamilyState(id), data);
      // Update the IDs list
      set(scheduleIdsState, (currentIds) => (currentIds.indexOf(id) === -1 ? [...currentIds, id] : currentIds));
    },
});

export const scheduleSearchLimitReachedState = atom<boolean>({
  key: 'scheduleSearchLimitReachedState',
  default: false,
});
