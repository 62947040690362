/**
 * This is a simple dialog that is controlled by Recoil state.
 *
 * Usage:
 *
 * setDialog({
 *   isOpen: true,
 *   title: 'Example title,
 *   description: 'Example description',
 *   hasLogo: true,
 *     onClose: () => console.log('Optional callback'),
 *  }),
 */

import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useRecoilState } from 'recoil';

import { Image } from '@/components/image';
import { Dialog, DialogContent, dialogStyles } from '@/components/ui/dialog';
import { dialogState } from '@/state/dialog';

import { Heading } from '../heading';

const DialogSimple = () => {
  const [dialog, setDialog] = useRecoilState(dialogState);

  const { hasLogo, title, description, body, footnote, buttons, isOpen, onClose, maxWidth } = dialog;

  const handleClose = () => {
    setDialog({ isOpen: false });

    if (onClose) {
      onClose();
    }
  };

  const { logo, description: descriptionStyle } = dialogStyles();

  return (
    <Dialog open={isOpen}>
      <DialogContent
        onClose={handleClose}
        buttons={buttons}
        aria-describedby={description || undefined}
        footnote={footnote}
        maxWidth={maxWidth}
      >
        {hasLogo && (
          <div className={logo()}>
            <Image src="/assets/icons/logo-colored.svg" alt="Fluent Cargo Logo" />
          </div>
        )}

        {title && (
          <Heading variant="h3" className="mb-base pr-lg" as={DialogPrimitive.DialogTitle}>
            {title}
          </Heading>
        )}

        {description && (
          <DialogPrimitive.Description className={descriptionStyle()}>{description}</DialogPrimitive.Description>
        )}
        {body && (
          <DialogPrimitive.Description className={descriptionStyle()} dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export { DialogSimple };
