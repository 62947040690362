/**
 * Format seconds to a nicer format
 * @param {num} seconds
 */
const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (!minutes || minutes < 0) {
    return ``;
  }

  if (minutes < 60) {
    // Less than an hour. ie. 32min
    return `${minutes}min`;
  }

  if (hours > 24) {
    // more than a day. i.e 2 days
    const days = Math.floor(hours / 24);
    const remainingHours = Math.floor(hours % 24);
    const remainingHoursStr = remainingHours > 0 ? ` ${remainingHours}h` : '';
    return `${days} ${days > 1 ? 'days' : 'day'}${remainingHoursStr}`;
  }

  const remainingMinutes = Math.floor(minutes % 60);
  if (remainingMinutes < 6) {
    // 2hrs
    return hours === 1 ? `${hours}hr` : `${hours}hrs`;
  }
  // 10h 15m
  return `${hours}h ${remainingMinutes}m`;
};

export { formatDuration };
