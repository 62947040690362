import { Button, ButtonProps } from '@/components/button';
import { getDatoLinkHref } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';

export interface ButtonPropsExtended extends ButtonProps {
  text: string;
}

export interface ButtonGroupProps {
  buttons?: ButtonPropsExtended[];
  stackedButtons?: boolean; // @NOTE: Always stacked < sm
  className?: string;
  size?: 'lg';
}

const ButtonGroup = ({ buttons, stackedButtons, className, size, ...props }: ButtonGroupProps) => {
  if (!buttons) return null;

  const { base, listItem, button } = buttonGroupStyles({ stackedButtons });

  return (
    <ul className={base({ className })} {...props}>
      {buttons.map(({ text, href, variant: buttonVariant, internalLink, externalLink, ...buttonProps }) => {
        const buttonHref = href || getDatoLinkHref({ internalLink, externalLink }) || '';

        return (
          <li key={`${buttonHref || text}-button-group`} className={listItem()}>
            <Button
              href={buttonHref}
              variant={buttonVariant || 'primaryLight'}
              className={button()}
              size={size === 'lg' ? 'large' : 'medium'}
              isRounded={size === 'lg'}
              {...buttonProps}
            >
              {text}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

const buttonGroupStyles = tv({
  slots: {
    base: 'flex flex-col flex-wrap items-center sm:flex-row md:flex-nowrap',
    button: 'w-full sm:w-auto',
    listItem: 'mb-2 w-full last:mr-0 sm:mr-4 sm:w-auto md:mb-0',
  },
  variants: {
    stackedButtons: {
      true: {
        base: 'align-items-unset w-full flex-col md:flex-wrap',
        button: 'w-full sm:w-full',
        listItem: 'mb-2 mr-0 w-full sm:mr-0 sm:w-full md:mb-2',
      },
    },
  },
});

export { ButtonGroup };
