// Local-Storage Helpers

/**
 * This exists for trying to serialize the value back to JSON.
 * If it cannot serialize it, then it was a string value given.
 */
const tryParse = (value?: string | null) => {
  if (!value) return null;

  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

/**
 * Retrieves the stored item by Key
 */
export const getStoredItem = (key: string) => {
  if (hasLocalStorage() && key) {
    try {
      return tryParse(localStorage.getItem(key));
    } catch {
      console.log('LocalStorage Get blocked/failed');
      return null;
    }
  }
  return null;
};

/**
 * Updates the stored item by Key
 */
export const setStoredItem = (key: string, item: unknown) => {
  if (hasLocalStorage() && key) {
    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch {
      console.log('LocalStorage Set blocked/failed');
    }
  }
};

/**
 * Deletes the stored item by Key
 */
export const removeStoredItem = (key: string) => {
  if (hasLocalStorage() && key) {
    try {
      localStorage.removeItem(key);
    } catch {
      console.log('LocalStorage Remove blocked/failed');
    }
  }
};

/**
 * Checks Localstorage support
 * @returns boolean
 */
export const hasLocalStorage = (): boolean => {
  return typeof window !== 'undefined' && typeof Storage !== 'undefined';
};
