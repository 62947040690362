/**
 * This context handles Google Analytics tracking
 */

// @TODO determine if this file can be deprecated and removed as its now replaced by GTAG tags

import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useRecoilValue } from 'recoil';

import { GOOGLE_ADS_ID, GOOGLE_ANALYTICS_ID } from '@/lib/constants';
import { featuresState } from '@/state/features';
import { AnalyticsContextInterface } from '@/types/context-types';
import { isProductionEnv } from '@/utils/helpers';

// Is Production
const IS_PROD = isProductionEnv();
// Show logs in console, convert showLogs to a boolean
const SHOW_LOGS = process.env.NEXT_PUBLIC_CONFIG_SHOW_LOGS === 'true';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AnalyticsContext = React.createContext<AnalyticsContextInterface>(undefined!);

interface Props {
  children: React.ReactNode;
}

export const AnalyticsContextProvider = (props: Props) => {
  const router = useRouter();
  const features = useRecoilValue(featuresState);

  // Only track users on Production who have 'Do Not Track' set to false
  const SHOULD_TRACK = IS_PROD && !features.doNotTrack;

  // Create a default state to keep track of whether GA
  // has been initialized, we can more state if needed
  const [analytics, setAnalytics] = useState({
    isInitialised: false,
  });

  const gaEvent = ({ category = '', action = '', label = '' }) => {
    if (analytics.isInitialised && SHOULD_TRACK) {
      ReactGA.event({
        category,
        action,
        label,
      });
    }
  };

  useEffect(() => {
    const { isInitialised } = analytics;

    // We create a function handle all route changes that occur
    // and track a users movements across pages in our app
    const handleRouteChange = () => {
      if (SHOULD_TRACK) {
        ReactGA.send({ hitType: 'pageview' });
      } else {
        console.log('×', router.asPath);
      }
    };

    if (SHOULD_TRACK) {
      if (!isInitialised) {
        ReactGA.initialize([
          {
            trackingId: GOOGLE_ANALYTICS_ID,
          },
          {
            trackingId: GOOGLE_ADS_ID,
          },
        ]);

        // Handle all route changes
        if (router) router.events.on('routeChangeComplete', handleRouteChange);

        setAnalytics((prev) => ({
          ...prev,
          isInitialised: true,
        }));

        // Send intial page load
        ReactGA.send({ hitType: 'pageview' });
      }
    } else if (SHOW_LOGS) {
      console.log('%c× GA logging is disabled', 'color:firebrick;font-weight:bold');
    }

    return () => {
      if (router) router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [analytics, router]);

  // Ignorigin error below as we are going to drop Context soon
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const defaultContext: AnalyticsContextInterface = {
    analytics,
    setAnalytics,
    gaEvent,
  };

  return <AnalyticsContext.Provider value={defaultContext} {...props} />;
};

export const { Consumer: AnalyticsConsumer } = AnalyticsContext;
