import * as DialogPrimitive from '@radix-ui/react-dialog';
import { signIn, useSession } from 'next-auth/react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { Dialog, DialogContent, dialogStyles } from '@/components/ui/dialog';
import { lockedDialogContentState, lockedDialogIsOpenState } from '@/state/user';
import { getUpgradeAccountUrl } from '@/utils/helpers/urls';
import { cn } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

interface Props extends DialogPrimitive.DialogProps {
  fullWidth?: boolean;
}

const DialogLocked = ({ children, fullWidth, ...props }: Props) => {
  const { data: session } = useSession();
  const lockedDialogIsOpen = useRecoilValue(lockedDialogIsOpenState);
  const lockedDialogContent = useRecoilValue(lockedDialogContentState);
  const resetLockedDialog = useResetRecoilState(lockedDialogIsOpenState);
  const resetLockedDialogContent = useResetRecoilState(lockedDialogContentState);

  const handleSignIn = () => {
    signIn();

    trackUser.event('Go to: Sign-in', {
      location: 'Login Dialog',
    });
  };

  const handleClose = () => {
    resetLockedDialog();
    resetLockedDialogContent();
  };

  const { description, logo } = dialogStyles();

  const renderContent = () => {
    // Only show the expanded content if the user is signed in
    if (session) {
      return (
        <>
          <Heading variant="h3" className="mb-sm pr-lg" as={DialogPrimitive.Title}>
            {lockedDialogContent.title || 'An account upgrade is required to access this feature.'}
          </Heading>
          <DialogPrimitive.Description className={description()}>
            {lockedDialogContent.description ||
              'Upgrade your account to get access to this feature including more usage and other benefits.'}{' '}
            <Link className="text-lightBlue-500" href="/plans" target="_blank">
              More details
            </Link>
          </DialogPrimitive.Description>
          <Button href={getUpgradeAccountUrl()} target="_blank" className="mt-base">
            Upgrade your account
          </Button>
        </>
      );
    }

    return (
      <>
        <Heading variant="h3" className="mb-sm pr-lg" as={DialogPrimitive.Title}>
          A Fluent Cargo account is required to use this feature.
        </Heading>

        <DialogPrimitive.Description className={description()}>
          Some features are only available for full use if you have an account with us.{' '}
          <b>Creating an account is free</b> and only takes a few seconds!
        </DialogPrimitive.Description>
      </>
    );
  };

  return (
    <Dialog open={lockedDialogIsOpen} {...props}>
      <div
        className={cn({
          'w-full': fullWidth,
        })}
      >
        {children}
      </div>
      <DialogContent onClose={handleClose}>
        <div className={logo()}>
          <Image src="/assets/icons/logo-colored.svg" alt="Fluent Cargo Logo" />
        </div>

        {renderContent()}

        {!session && (
          <Button onClick={() => handleSignIn()} className="mt-lg w-full">
            Sign in now
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { DialogLocked };
