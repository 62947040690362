import { tv } from '@/utils/styles';

interface Props {
  className?: string;
  darkTheme?: boolean;
}

const LoadingIndicator = ({ className, darkTheme }: Props) => {
  const { wrapper, dot } = styles({ darkTheme });
  return (
    <span className={wrapper({ className })}>
      <span className={dot()} />
      <span className={dot()} />
      <span className={dot()} />
    </span>
  );
};

const styles = tv({
  slots: {
    wrapper:
      'mr-1 flex self-center p-2 transition-colors duration-150 [&_:nth-child(1)]:animate-blink-1 [&_:nth-child(2)]:animate-blink-2 [&_:nth-child(3)]:animate-blink-3',
    dot: 'mr-[3px] inline-block h-1 w-1 rounded-full bg-grey-300 align-top last:m-0',
  },
  variants: {
    darkTheme: {
      true: {
        dot: 'bg-grey-500',
      },
    },
  },
});

export { LoadingIndicator };
