'use client';

import { useSetRecoilState } from 'recoil';

import { notificationsState } from '@/state/notifications';
import { NotificationInterface } from '@/types';

const useNotifications = () => {
  const setNotifications = useSetRecoilState(notificationsState);

  const addNotification = ({
    heading,
    description,
    onConfirm,
    type = 'background',
    duration = 5000,
    variant,
    confirmButtonText,
    icon,
  }: Omit<NotificationInterface, 'id'>) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id: Date.now(), heading, description, onConfirm, type, duration, variant, confirmButtonText, icon },
    ]);
  };

  const removeNotification = (notificationId: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== notificationId),
    );
  };

  return {
    addNotification,
    removeNotification,
  };
};

export { useNotifications };
