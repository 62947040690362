import { groupBy, sortBy, uniq } from 'lodash';

import {
  AIRCRAFT_TYPE_OPTIONS,
  CARRIER_AIRLINE,
  CARRIER_OCEAN,
  ENTIRE_AVAILABLE,
  FILTER_BODYTYPE_NARROW,
  FILTER_BODYTYPE_WIDE,
  FILTER_HAULING_FREIGHT,
} from '@/lib/constants';
import { CarrierModel } from '@/lib/models/carrier/types';
import { RouteScheduleInterface, StandaloneScheduleFilterOptionItem } from '@/types';
import { CarrierTypes } from '@/types/api-types';
import { getCarrierName } from '@/utils/carrier';
import { getPreferredIdentifier } from '@/utils/helpers';
import { getCarrierType } from '@/utils/helpers/search-filters';

interface CarrierOption {
  label: string;
  value: string;
  type: CarrierTypes;
}

export const getScheduleCarriers = (schedules: RouteScheduleInterface[]) => {
  const carrierIds = schedules.reduce((acc, schedule) => {
    const scheduleCarrierIds = schedule.attributes.primaryCarriers;
    return [...acc, ...scheduleCarrierIds];
  }, [] as string[]);

  // Remove duplicates
  return uniq(carrierIds);
};

export const getCarrierOptions = (carriers: CarrierModel[]): CarrierOption[] => {
  return carriers.map((carrier) => {
    const preferredIdentifier = getPreferredIdentifier(carrier.identifiers);

    const carrierName = getCarrierName(carrier);

    const value = carrier.id || '';
    const carrierType = getCarrierType(preferredIdentifier);

    return {
      label: carrierName,
      value,
      type: carrierType,
      id: preferredIdentifier?.value,
    };
  });
};

type OrderByLabelType = typeof CARRIER_AIRLINE | typeof CARRIER_OCEAN | 'Other';

export const groupByCarrierType = (
  options: StandaloneScheduleFilterOptionItem[] | undefined,
  types: OrderByLabelType[],
) => {
  return groupBy(
    sortBy(
      options?.filter((option) => option.type && types.includes(option.type as OrderByLabelType)),
      'label',
    ),
    (option) => option.label[0].toUpperCase(),
  );
};

/**
 * Creates the options for the Aircraft Type filter
 * based on which are available in the schedules
 */
export const getAircraftTypeOptions = (schedules: RouteScheduleInterface[]) => {
  const attributesFound = schedules.reduce(
    (result, option) => ({
      ...result,
      [FILTER_HAULING_FREIGHT]:
        result[FILTER_HAULING_FREIGHT] || option.attributes?.hasFreightOnlyVehicles === ENTIRE_AVAILABLE,
      [FILTER_BODYTYPE_WIDE]:
        result[FILTER_BODYTYPE_WIDE] || option?.attributes?.aircraft?.widebody === ENTIRE_AVAILABLE,
      [FILTER_BODYTYPE_NARROW]:
        result[FILTER_BODYTYPE_NARROW] || option?.attributes?.aircraft?.narrowbody === ENTIRE_AVAILABLE,
    }),
    {
      [FILTER_HAULING_FREIGHT]: false,
      [FILTER_BODYTYPE_WIDE]: false,
      [FILTER_BODYTYPE_NARROW]: false,
    },
  );

  const options = [
    {
      label: AIRCRAFT_TYPE_OPTIONS.FREIGHTER,
      value: FILTER_HAULING_FREIGHT,
      disabled: !attributesFound[FILTER_HAULING_FREIGHT],
    },
    {
      label: AIRCRAFT_TYPE_OPTIONS.WIDEBODY,
      value: FILTER_BODYTYPE_WIDE,
      disabled: !attributesFound[FILTER_BODYTYPE_WIDE],
    },
    {
      label: AIRCRAFT_TYPE_OPTIONS.NARROWBODY,
      value: FILTER_BODYTYPE_NARROW,
      disabled: !attributesFound[FILTER_BODYTYPE_NARROW],
    },
  ];

  return options;
};
