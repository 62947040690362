import { capitalize, uniq } from 'lodash';

import {
  AIRPORT,
  CARRIER_AIRLINE,
  CARRIER_OCEAN,
  CARRIER_RAIL,
  CITY,
  MODE_AIR,
  MODE_INTERMODAL,
  MODE_OCEAN,
  MODE_PLANE,
  MODE_RAIL,
  MODE_ROAD,
  MODE_SHIP,
  MODE_TRANSFER,
  MODE_TRUCK,
  MODE_WATER,
  PLACE_PORT,
  RAIL_TERMINAL,
  SEAPORT,
  TRAIN_STATION,
  TRANSFER_STOP,
} from '@/lib/constants';
import { ShipmentType } from '@/lib/models/shipment/types';
import { RouteSegmentModeVerb } from '@/types';
import { NodeType, PlaceType, RouteApiV3Interface, RouteTransferApiType, SegmentModeType } from '@/types/api-types';

/**
 * Convert Mode to a vehicle
 * Converts a SegmentModeType to vehicle type
 */
const convertModeType = (mode: string, caps?: boolean): string => {
  let string = '';

  switch (mode) {
    case MODE_AIR:
      string = MODE_PLANE;
      break;
    case MODE_RAIL:
      string = MODE_RAIL;
      break;
    case MODE_WATER:
      string = MODE_SHIP;
      break;
    case MODE_ROAD:
      string = MODE_ROAD;
      break;
    default:
      string = MODE_TRANSFER;
  }

  return caps ? capitalize(string) : string;
};

const covertModeToVehicle = (mode: string): string => {
  switch (mode) {
    case MODE_AIR:
      return capitalize(MODE_PLANE);
    case MODE_RAIL:
      return capitalize(MODE_RAIL);
    case MODE_WATER:
      return capitalize(MODE_SHIP);
    case MODE_ROAD:
      return capitalize(MODE_TRUCK);
    default:
      return mode;
  }
};

/**
 * Converts segment Mode to Vehicle verb
 * @param mode SegmentMode
 * @returns flights|trains|vessels|trucks
 */
const convertModeToVehicleVerbs = (mode: string): string => {
  switch (mode) {
    case MODE_AIR:
      return 'flights';
    case MODE_RAIL:
      return 'trains';
    case MODE_WATER:
      return 'vessels';
    case MODE_ROAD:
      return 'trucks';
    default:
      return mode;
  }
};

/**
 * Converts segment Mode to transport type
 * @param mode SegmentMode
 * @returns air|ocean|rail|road
 */
const convertModeToTransportType = (mode: string): string => {
  switch (mode) {
    case MODE_AIR:
      return MODE_AIR;
    case MODE_RAIL:
      return MODE_RAIL;
    case MODE_WATER:
      return MODE_OCEAN;
    case MODE_ROAD:
      return MODE_ROAD;
    default:
      return mode;
  }
};

/**
 * Convert Mode to a verb
 * Converts a SegmentModeType to verb description
 */
const convertModeVerb = (
  modeType: SegmentModeType,
  transferType?: RouteTransferApiType | string | null,
  postFix: 'to' | 'from' | '' = 'to',
): RouteSegmentModeVerb | string => {
  let verb = '';

  // Handle Transfers
  if (modeType === MODE_TRANSFER) {
    switch (transferType) {
      case TRANSFER_STOP:
        return 'Stops at';
      default:
        return 'Transfer at';
    }
  }

  // Handle Vehicle modes
  switch (modeType) {
    case MODE_AIR:
      verb = `Plane ${postFix}`;
      break;
    case MODE_RAIL:
      verb = `Train ${postFix}`;
      break;
    case MODE_WATER:
      verb = `Ship ${postFix}`;
      break;
    case MODE_ROAD:
      verb = `Truck ${postFix}`;
      break;
    default:
      verb = `${modeType} ${postFix}`;
  }

  return verb.trim();
};

/**
 * Convert Node to Mode
 * Converts a NodeType to a SegmentModeType
 */
const convertNodeTypeToMode = (type: NodeType | PlaceType): SegmentModeType => {
  switch (type) {
    case AIRPORT:
      return MODE_AIR;
    case TRAIN_STATION:
      return MODE_RAIL;
    case SEAPORT:
      return MODE_WATER;
    case CITY:
      return MODE_ROAD;
    default:
      return MODE_ROAD;
  }
};

/**
 * Converts mode (water, air) to carrier type (Airline, Ocean carrier)
 */
const convertModeToCarrierType = (mode: SegmentModeType): string => {
  switch (mode) {
    case MODE_AIR:
      return CARRIER_AIRLINE;
    case MODE_WATER:
      return CARRIER_OCEAN;
    case MODE_RAIL:
      return CARRIER_RAIL;
    default:
      return mode;
  }
};

/**
 * Get Modes Array
 * Gets an array of modes across the Route
 */
const getRouteModesArray = (route: RouteApiV3Interface) => {
  const { blocks } = route;
  const modes = blocks.map((b) => b.alternatives[0].type);
  const filteredModes = modes.filter((m) => m && m !== MODE_TRANSFER);
  return uniq(filteredModes);
};

/**
 * Get Primary Mode
 * Determines the primary mode(s) of a route
 */
const getPrimaryMode = (modes: SegmentModeType[]): SegmentModeType => {
  if (modes.includes(MODE_AIR)) return MODE_AIR;
  if (modes.includes(MODE_WATER)) return MODE_WATER;
  if (modes.includes(MODE_RAIL)) return MODE_RAIL;
  return MODE_ROAD;
};

/**
 * Convert Mode to airport|seaport|railTerminal
 * Returns a port specific string based on the mode
 */
const convertModeToPortType = (mode: SegmentModeType): string => {
  switch (mode) {
    case MODE_AIR:
      return AIRPORT;
    case MODE_WATER:
      return SEAPORT;
    case MODE_RAIL:
      return RAIL_TERMINAL;
    default:
      return PLACE_PORT;
  }
};

/**
 * Get Port String
 * Returns a port specific string based on the mode
 */
const getPortSpecificRouteString = (mode: SegmentModeType): string => {
  if (mode === MODE_AIR) return 'Airports on this route';
  if (mode === MODE_WATER) return 'Seaports on this route';
  if (mode === MODE_RAIL) return 'Rail terminals on this route';
  return 'Ports on this route';
};

/**
 * Mode Freight Type
 */
const getFreightTransportTypeString = (mode: SegmentModeType): string => {
  if (mode === MODE_AIR) return 'Air Freight';
  if (mode === MODE_WATER) return 'Ocean Freight';
  if (mode === MODE_RAIL) return 'Rail Freight';
  if (mode === MODE_ROAD) return 'Road Freight';
  return 'Freight transport';
};

/**
 * Placeholder string type
 * Returns a specific placeholder based based on vehicle mode
 */
const getModePlaceholderText = (mode: SegmentModeType): string => {
  if (mode === MODE_AIR) return 'Airport code or name';
  if (mode === MODE_WATER) return 'Locode or port name';
  return 'Port code or name';
};

const convertShipmentModeToSegmentMode = (type: ShipmentType): SegmentModeType => {
  switch (type) {
    case MODE_AIR:
      return MODE_AIR;
    case MODE_INTERMODAL:
      return MODE_WATER;
    default:
      return MODE_WATER;
  }
};

const convertSegmentModeVehicleType = (type?: SegmentModeType): string => {
  switch (type) {
    case MODE_AIR:
      return 'Aircraft';
    case MODE_WATER:
      return 'Vessel';
    case MODE_RAIL:
      return 'Rail';
    case MODE_ROAD:
      return 'Road';
    default:
      return 'unknown';
  }
};

export {
  convertModeToCarrierType,
  convertModeToPortType,
  convertModeToTransportType,
  convertModeToVehicleVerbs,
  convertModeType,
  convertModeVerb,
  convertNodeTypeToMode,
  convertSegmentModeVehicleType,
  convertShipmentModeToSegmentMode,
  covertModeToVehicle,
  getFreightTransportTypeString,
  getModePlaceholderText,
  getPortSpecificRouteString,
  getPrimaryMode,
  getRouteModesArray,
};
