'use client';

/* eslint-disable jsx-a11y/anchor-has-content */
import { isObject } from 'lodash';
import NextLink from 'next/link';
import React from 'react';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  as?: string;
  replace?: boolean;
  scroll?: string;
  shallow?: boolean;
  passHref?: boolean;
  prefetch?: boolean;
  isLoading?: boolean;
  iconAnimX?: boolean;
  iconAnimY?: boolean;
}

const Link = React.forwardRef(
  (
    {
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      isLoading,
      iconAnimX,
      iconAnimY,
      className,
      ...props
    }: Props,
    ref: any,
  ) => {
    // Internal link, use this
    if (!String(href).startsWith('http') || isObject(href)) {
      return (
        <NextLink href={href} replace={replace} shallow={shallow} prefetch={prefetch || false} passHref legacyBehavior>
          <a {...props} ref={ref} className={className} />
        </NextLink>
      );
    }

    // Otherwise it's a normal link
    return <a {...props} href={href} ref={ref} className={className} />;
  },
);

Link.displayName = 'Link';

export { Link };
