/**
 * Custom hook to poll for new invites and show a notification
 */

import Cookies from 'js-cookie';
import { usePathname, useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import pluralize from 'pluralize';
import { useState } from 'react';
import useSWR from 'swr';

import { useNotifications } from '@/hooks/use-notifications';
import { COOKIE_INVITE_NOTIFICATION_SEEN, NEXT_AUTH_STATUSES, ORG_INVITES_URL } from '@/lib/constants';
import { OrgsUsersInvitesDataInterface } from '@/types/database-types';
import { request } from '@/utils/api/request';
import { getInvitesUrl } from '@/utils/helpers/api-urls';

// Poll every 10 minutes
const INVITES_POLLING_INTERVAL = 1000 * 60 * 10;

export const usePollNewInvites = () => {
  const [hasSeenNotification, setHasSeenNotification] = useState(false);

  const { status } = useSession();
  const { addNotification } = useNotifications();
  const router = useRouter();
  const pathname = usePathname();

  // Conditionally fetch the data only when the user is authenticated
  const { data } = useSWR<OrgsUsersInvitesDataInterface>(
    status === NEXT_AUTH_STATUSES.AUTHENTICATED ? getInvitesUrl : null,
    request,
    {
      refreshInterval: hasSeenNotification ? INVITES_POLLING_INTERVAL : 0,
      revalidateOnFocus: false,
      errorRetryCount: 2,
      onSuccess: () => {
        checkForNewInvitesAndNotify();
      },
    },
  );

  const checkForNewInvitesAndNotify = async () => {
    if (data?.data && !pathname?.startsWith('/auth/')) {
      const inviteCount = data.data.length;

      const lastNotificationTime = Cookies.get(COOKIE_INVITE_NOTIFICATION_SEEN);

      // If the last notification was more than 24 hours ago,
      // or there was no last notification
      if (!lastNotificationTime || Date.now() - Number(lastNotificationTime) > INVITES_POLLING_INTERVAL) {
        addNotification({
          heading: `New workspace ${pluralize('invite', inviteCount)}`,
          description: `You have been invited to join a shared workspace.`,
          onConfirm: () => {
            router.push(ORG_INVITES_URL);
          },
          confirmButtonText: `View ${pluralize('invite', inviteCount)}`,
          duration: 50000,
          icon: 'users',
        });

        // Update the last notification time
        // @NOTE: We have to set an expiry date for the cookie,
        // otherwise it will be a session cookie and we just need it
        // to stick around for more than 24 hours
        Cookies.set(COOKIE_INVITE_NOTIFICATION_SEEN, Date.now().toString(), { expires: 2 });

        setHasSeenNotification(true);
      }
    }
  };
};
