import { head, last, uniqBy } from 'lodash';

import { MODE_TRANSFER, TRANSFER_STOP } from '@/lib/constants';
import {
  LocationInterface,
  RouteLocationInterface,
  RouteScheduleSegmentInterface,
  RouteSegmentInterface,
} from '@/types';
import { RouteLocationApiV2Interface } from '@/types/api-types';

/**
 * Gets unique locations from segments
 * Returns a list of unique locations in order from all segments provided
 */
export const getUniqueSegmentLocations = (
  segments: (RouteScheduleSegmentInterface | RouteSegmentInterface)[],
): RouteLocationInterface[] => {
  const locations = segments.reduce(
    (accum, segment) => {
      const { from, to } = segment;
      return [...accum, from, to];
    },
    [] as (RouteLocationInterface | null)[],
  );
  const filteredLocations = locations.filter((location): location is RouteLocationInterface => !!location);
  return uniqBy(filteredLocations, 'nodeReference');
};

/**
 * Gets unique location IDs from segments
 * Returns a list of unique location IDs in order from all segments provided
 */
export const getUniqueSegmentLocationIds = (
  segments: (RouteScheduleSegmentInterface | RouteSegmentInterface)[],
): string[] => {
  const locations = getUniqueSegmentLocations(segments);
  return locations.map((location) => location.nodeReference);
};

/**
 * Gets transfer locations from segments
 * Returns a list of unique locations in order from all segments provided
 */
export const getTransferLocations = (
  segments: (RouteScheduleSegmentInterface | RouteSegmentInterface)[],
): RouteLocationInterface[] => {
  const transferLocations = segments.reduce((accum, segment) => {
    const { from, transferType, mode } = segment;
    if (mode === MODE_TRANSFER && transferType !== TRANSFER_STOP) {
      return [...accum, from];
    }
    return accum;
  }, [] as RouteLocationInterface[]);

  // Get starting and ending locations
  const firstSegment = head(segments);
  const lastSegment = last(segments);
  const joinedLocations = [firstSegment?.from, ...transferLocations, lastSegment?.to].filter(
    Boolean,
  ) as RouteLocationInterface[];

  return uniqBy(joinedLocations, 'nodeReference');
};

export const shortenLocationName = (name: string): string => {
  const parts = name.split('International');
  if (parts.length === 1) {
    const moreParts = name.split('Airport');
    return moreParts[0];
  }
  return parts[0];
};

/**
 * Gets Location name
 * Returns a Location Name as a string
 */
export const getLocationName = (location: RouteLocationApiV2Interface, shorten = false): string => {
  const shortName = location?.names?.short?.length ? location.names.short[0] : '';
  const longName = location?.names?.long?.length ? location.names.long[0] : '';
  const name = shortName || longName;

  if (name && shorten) {
    return shortenLocationName(name);
  }
  return name;
};

/**
 * Sorts a list of locations by the keys provided in the sortOrder array
 */
export const sortLocationsBySortKey = (locations: LocationInterface[], sortOrder: string[]) => {
  // Sorting the array based on the sortOrder
  return locations.sort((a, b) => {
    const indexA = sortOrder.indexOf(a.placeId!);
    const indexB = sortOrder.indexOf(b.placeId!);

    // If both elements are in the sortOrder array, compare their positions
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If one element is in the sortOrder array and the other is not,
    // place the element that is in the sortOrder array first
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    // If neither element is in the sortOrder array, maintain their original order
    return 0;
  });
};
