import { isEmpty, isNil } from 'lodash';

import { ENVS, MODE_AIR, MODE_TRANSFER, TRANSFER_STOP } from '@/lib/constants';
import { RouteTransferApiType, SegmentModeType } from '@/types/api-types';

import {
  createCarrierSearchUrl,
  createPlaceDetailsSearchUrl,
  createPlacesSearchUrl,
  createRouteSearchUrl,
  createScheduleSearchUrl,
} from './api-urls';
import { buildRouteMapPreview, buildStaticMapUrl, buildStaticMapUrlWithPath } from './build-static-map-url';
import { getPreferredIdentifier } from './get-preferred-identifier';
import { hexToRgba } from './hex-to-rgb';
import { intersectObjectsLeft } from './intersect-objects';
import { createMetaTagsWithCustomTitleAndDescription } from './meta-tags';
import {
  createCarrierPageUrl,
  createPortInsightsDeepLinkUrl,
  createPortPageUrl,
  createRoutePageUrl,
  createRoutePageUrlObject,
  createSearchPageUrl,
} from './page-urls';
import { generateRouteKey, generateSegmentKey } from './route-key';
import { getUserSearchCount, incrementUserSearchCount, initialiseUserSearchCount } from './search-limits';
import { joinByCommaAnd } from './strings';
import { createQueryString, getUrlDomain, isValidUrl } from './urls';

/**
 * Generates a random ID
 * @returns string
 */
const generateId = (): string => Math.random().toString().replace('.', '');

const generateSimpleHash = (str: string): number => {
  let hash: number = 0;
  for (let i: number = 0, len: number = str.length; i < len; i += 1) {
    const chr: number = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Checks if we're running Production
 * @returns boolean
 */
const isProductionEnv = (): boolean => {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === ENVS.PROD;
};

const isLocalEnv = (): boolean => {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === ENVS.LOCAL || process.env.STORYBOOK_ENVIRONMENT === ENVS.LOCAL;
};

// Convert Transfer Type
// @TODO get this fixed on the backend
export const convertTransferType = (
  transferType: RouteTransferApiType,
  mode: SegmentModeType,
): RouteTransferApiType => {
  // Currently some Flight transfers are designated as 'STOPS' this is incorrect, they should be transfers
  if (transferType === TRANSFER_STOP && mode === MODE_AIR) return MODE_TRANSFER;
  return transferType;
};

const removeEmptyValues = (data: Record<string, any>) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      if (value.length) {
        return { ...acc, [key]: value };
      }
      return acc;
    }

    if (!isNil(value) || !isEmpty(value)) {
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, {});
};

const copyToClipboard = (text: string) => {
  if (typeof window === 'undefined') return;
  navigator.clipboard.writeText(text);
};

export {
  buildRouteMapPreview,
  buildStaticMapUrl,
  buildStaticMapUrlWithPath,
  copyToClipboard,
  createCarrierPageUrl,
  createCarrierSearchUrl,
  createMetaTagsWithCustomTitleAndDescription,
  createPlaceDetailsSearchUrl,
  createPlacesSearchUrl,
  createPortInsightsDeepLinkUrl,
  createPortPageUrl,
  createQueryString,
  createRoutePageUrl,
  createRoutePageUrlObject,
  createRouteSearchUrl,
  createScheduleSearchUrl,
  createSearchPageUrl,
  generateId,
  generateRouteKey,
  generateSegmentKey,
  generateSimpleHash,
  getPreferredIdentifier,
  getUrlDomain,
  getUserSearchCount,
  hexToRgba,
  incrementUserSearchCount,
  initialiseUserSearchCount,
  intersectObjectsLeft,
  isLocalEnv,
  isProductionEnv,
  isValidUrl,
  joinByCommaAnd,
  removeEmptyValues,
};
