interface FontSizes {
  [key: string]: string;
}

const fontSizes: FontSizes = {
  xxs: '10px', // Avoid usage if you can
  xs: '11px', // Avoid usage if you can
  sm: '12px',
  baseSm: '14px',
  base: '16px',
  baseLg: '18px',
  md: '24px',
  lg: '32px',
  xl: '40px',
  xxl: '72px',
  xxxl: '80px',
};

export { fontSizes };
