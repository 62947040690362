import { forwardRef, Ref } from 'react';

import { tv } from '@/utils/styles';

export interface TextProps {
  as?: 'p' | 'span' | 'div';
  variant?: 'sm' | 'body' | 'secondary' | 'uppercase' | 'label' | 'chartLabel' | 'baseLg';
  children?: React.ReactNode | React.ReactNode[];
}

type ExtendedProps = TextProps & React.HTMLAttributes<HTMLParagraphElement>;

const Text = forwardRef(({ as, variant, className, ...props }: ExtendedProps, ref: Ref<HTMLParagraphElement>) => {
  const Element = as || 'p';

  return (
    <Element ref={ref} className={textStyles({ variant, className })} {...props}>
      {props.children}
    </Element>
  );
});

Text.displayName = 'Text';

const textStyles = tv({
  variants: {
    variant: {
      sm: 'text-sm font-normal leading-relaxed',
      secondary: 'text-baseSm font-normal leading-snug',
      body: 'text-base font-normal leading-relaxed',
      uppercase: 'text-baseSm font-semibold uppercase md:text-base',
      label: 'text-xs font-medium uppercase leading-tight',
      chartLabel: 'text-xxs leading-none',
      baseLg: 'text-baseLg leading-snug',
    },
  },
});

export { Text };
