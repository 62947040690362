import 'what-input';

import { DefaultTheme, ThemeProvider } from 'styled-components';

import { Fonts } from '@/components/fonts';

import { baseTheme } from '../../theme';
import { BaseStyles } from '../base-styles';

interface Props {
  children: React.ReactElement;
  theme?: DefaultTheme;
}

const UiComponentsProvider: React.FC<Props> = ({ children, theme = baseTheme }: Props) => (
  <ThemeProvider theme={theme}>
    <BaseStyles />
    {children}
    <Fonts />
  </ThemeProvider>
);

export { UiComponentsProvider };
