import { Color } from 'deck.gl/typed';
import { red as tailwindRed } from 'tailwindcss/colors';

const green = {
  50: '#EDF7E7',
  100: '#CDE7BD',
  200: '#B0D793',
  300: '#95C669',
  400: '#7CB540',
  500: '#65A417',
  600: '#468E13',
  700: '#2C780F',
  800: '#17610B',
  900: '#084A08',
  950: '#05330D',
};

const colors = {
  black: '#000',
  white: '#fff',
  error: tailwindRed[700],
  errorLight: tailwindRed[300],
  errorDark: tailwindRed[800],
  yellow: '#ffe96c',
  yellowMid: '#ffd84b',
  yellowDark: '#FECA10',
  accent: '#e7f7f7',
  softPurple: '#f1f3fa',
  brandDarkBlue: '#003968',
  brandSoftBlue: '#F0FAFF',
  transparent: 'transparent',
  // Currently these states are used for the UserMessage component
  // if we find they aren't suitable for other applications
  // then we can isolate them with the that component
  states: {
    success: {
      backgroundColor: green[50],
      color: green[600],
      borderColor: green[400],
    },
    error: {
      backgroundColor: tailwindRed[100],
      color: tailwindRed[700],
      borderColor: tailwindRed[500],
    },
    warning: {
      backgroundColor: '#fff3cd',
      color: '#8b6f22',
      borderColor: '#f0cb5b',
      vibrant: '#f8a706',
    },
    locked: '#eab523',
  },
  text: {
    primary: '#2D3237',
    secondary: '#7B7B7B',
  },
  forms: {
    states: {
      error: tailwindRed[700],
      errorLight: tailwindRed[300],
    },
  },
  grey: {
    50: '#FAFAFA',
    100: '#F6F6F6',
    200: '#EEEEEE',
    300: '#DBDEE1',
    350: '#C4CAD0',
    400: '#A8AFB7',
    500: '#7B7B7B',
    600: '#576370',
    700: '#49525D',
    800: '#2C3238',
    900: '#1a202c',
  },
  blue: {
    50: '#EAF2F7',
    100: '#D5E6F1',
    200: '#ACD1E7',
    300: '#6591B2',
    400: '#1F507D',
    500: '#003968',
    600: '#00325B',
    700: '#002B4E',
    800: '#002441',
    900: '#001D34',
  },
  lightBlue: {
    50: '#EAF2F6',
    100: '#D4EBFD',
    200: '#A1D7FF',
    300: '#60BBFF',
    400: '#34A8FF',
    500: '#0795FF',
    600: '#0086DF',
    700: '#0077BF',
    800: '#00669F',
    900: '#005480',
  },
  green,
  red: {
    ...tailwindRed,
  },
  border: {
    grey: '#E7EAF2',
  },
  background: {
    grey: '#F2F5FB',
  },
  transport: {
    plane: '#A4CC72',
    road: '#DBDEE1',
    ship: '#4FC2E4',
    rail: '#F5A357',
    transfer: '#A8AFB7',
  },
  status: {
    low: '#93D342',
    medium: '#F2B927',
    active: green[400],
    high: tailwindRed[600],
  },
  charts: {
    // Combo of our lightBlue, this https://learnui.design/tools/data-color-picker.html#palette and https://github.com/d3/d3-scale-chromatic#schemeSet2
    line: [
      '#0086DF',
      '#003f5c',
      '#665191',
      '#d45087',
      '#f95d6a',
      '#ff7c43',
      '#ffa600',
      '#66c2a5',
      '#fc8d62',
      '#8da0cb',
      '#e78ac3',
      '#a6d854',
      '#ffd92f',
      '#e5c494',
      '#b3b3b3',
    ],
    xAxis: '#e1e1e1',
    congestion: ['#0086DF', '#d45087', '#b3b3b3'],
  },
  social: {
    linkedIn: '#0077B5',
    google: '#4285f4',
  },
  maps: {
    connectionsArcSource: [5, 112, 191] as Color,
    connectionsArcTarget: [7, 149, 255] as Color, // lightBlue.500
  },
};

export { colors };
