import { uniq } from 'lodash';

import { MODE_TRANSFER } from '@/lib/constants';
import { PositionInterface, RouteScheduleSegmentInterface, RouteSegmentInterface } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { Fluent } from '@/utils/environment';

const { GOOGLE_API_KEY } = Fluent.env;

const defaultMapSize = '400x240';
const defaultMapZoom = 10;
const defaultMapStyles = [
  'style=feature:road|color:0xe1e1e1|visibility:simplified',
  'style=feature:road.local|visibility:off',
  'style=feature:road|element:labels|visibility:off',
  'style=feature:poi|visibility:off',
  'style=feature:transit|visibility:off',
  'style=feature:water|color:0xbee0ee',
  'style=feature:landscape|color:0xf2f2f2',
];

/**
 * Generates a Static Map centered on a latLng
 * @param location PositionInterface
 * @param size string (WxH)
 * @param zoom number
 * @returns string
 */
export const buildStaticMapUrl = (location: PositionInterface, size = defaultMapSize, zoom = defaultMapZoom) => {
  if (!location || !location.lng) return '';
  const { lat, lng } = location;

  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${size}&scale=2&maptype=roadmap&${defaultMapStyles.join(
    '&',
  )}&key=${GOOGLE_API_KEY}`;
};

/**
 * Generates a Static Map from an array of Segments
 * @param segments array of segments to draw
 * @param size WxH size of map
 * @returns url string
 */
export const buildStaticMapUrlWithPath = (
  segments: (RouteSegmentInterface | RouteScheduleSegmentInterface)[],
  size = defaultMapSize,
): string => {
  const locations = segments.map((segment) => {
    if (segment.mode === MODE_TRANSFER) return [];
    return [segment.from?.position, segment.to?.position];
  });

  const filteredLocations = ([] as (PositionInterface | undefined)[])
    .concat(...locations)
    .filter((item) => item) as PositionInterface[];
  const locationsAsStr = filteredLocations.map((loc) => `${loc.lat},${loc.lng}`);
  const uniqueLocations = uniq(locationsAsStr);
  const markers = `anchor:5,5|icon:https://i.imgur.com/9JOjsBl.png|${uniqueLocations.join('|')}`;
  const path = `color:0x2A4E87ff|weight:2|${uniqueLocations.join('|')}`;

  return `https://maps.googleapis.com/maps/api/staticmap?markers=${markers}&path=${path}&size=${size}&scale=2&maptype=roadmap&${defaultMapStyles.join(
    '&',
  )}&key=${GOOGLE_API_KEY}`;
};

/**
 * Generates a Static Map with Origin and Destination markers
 * @param from PlaceInterface
 * @param to PlaceInterface
 * @param size WxH size of map
 * @returns url string
 */
export const buildRouteMapPreview = (from: PlaceInterface, to: PlaceInterface, size = defaultMapSize): string => {
  const fromLocation = [from.location?.lat, from.location?.lng];
  const toLocation = [to.location?.lat, to.location?.lng];

  const locations = [fromLocation, toLocation];
  const markers = `anchor:5,5|icon:https://i.imgur.com/9JOjsBl.png|${locations.join('|')}`;
  const path = `color:0x2A4E87ff|weight:3|${locations.join('|')}`;

  return `https://maps.googleapis.com/maps/api/staticmap?markers=${markers}&path=${path}&size=${size}&scale=2&maptype=roadmap&${defaultMapStyles.join(
    '&',
  )}&key=${GOOGLE_API_KEY}`;
};
