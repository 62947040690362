import { UNKNOWN } from '@/lib/constants';
import { LocationInterface, RouteLocationInterface } from '@/types';

/**
 * Generates key for Route Lookup
 * @param from - The from location
 * @param to - The to location
 * @returns A route lookup key based on the from to
 */
export const generateRouteKey = (from: LocationInterface, to: LocationInterface): string => {
  if (!from || !to) return '';
  const sourcePosition = from.location;
  const toPosition = to.location;

  // Build and return string key as per backend specifications
  return `${roundLatLngForKey(sourcePosition?.lat || 0)}_${roundLatLngForKey(
    sourcePosition?.lng || 0,
  )}_${roundLatLngForKey(toPosition?.lat || 0)}_${roundLatLngForKey(toPosition?.lng || 0)}`;
};

interface SimplePosition {
  lat: number;
  lng: number;
}

export const getSegmentKeyString = ({
  sourcePosition,
  targetPosition,
}: {
  sourcePosition: SimplePosition;
  targetPosition: SimplePosition;
}) => {
  return `${roundLatLngForKey(sourcePosition.lat)}_${roundLatLngForKey(sourcePosition.lng)}_${roundLatLngForKey(
    targetPosition.lat,
  )}_${roundLatLngForKey(targetPosition.lng)}`;
};

/**
 * Generates Path key for Polyline Lookup
 * @param from - The from location
 * @param to - The to location
 * @returns A polyline lookup key based on the from to
 */
export const generateSegmentKey = (
  from?: RouteLocationInterface | LocationInterface | null,
  to?: RouteLocationInterface | LocationInterface | null,
): string => {
  if (!from || !to) return '';
  // Due to the way the Polylines are keyed, we need to always use the Target/Source location as the origin and the Node as the destination.
  // This means means for the last segment we need to swap the from/to around
  const fromPos = 'position' in from ? from.position : from.location;
  const toPos = 'position' in to ? to.position : to.location;

  // If the "To" Type is unknown, assume is target location and swap pair to build key
  // If From is also unknown, assume is Truck E2E route and use from,to
  const isToTarget = to.type === UNKNOWN && from.type !== UNKNOWN;
  const sourcePosition = isToTarget ? toPos : fromPos;
  const targetPosition = isToTarget ? fromPos : toPos;

  // Build and return string key as per backend specifications
  return sourcePosition && targetPosition ? getSegmentKeyString({ sourcePosition, targetPosition }) : '';
};

/**
 * Rounds LatLngs into a constant num
 * @param num - number
 * @returns rounded number
 */
const roundLatLngForKey = (num: number) => {
  // Note: this logic mimics Backend logic for generating the Keys
  return Math.floor(num * 1000);
};
