// Formats Frequency into a human friendly string
const formatFrequency = (perHour: number): string => {
  const perDay = perHour * 24;
  const perWeek = perDay * 7;
  const perMonth = (perWeek * 52) / 12;

  if (perDay > 40) return 'Every 30mins';
  if (perDay > 23) return 'Hourly';
  if (perDay > 11) return 'Every 1-2 hours';
  if (perDay > 4) return 'Every few hours';
  if (perDay > 2) return '2-4 times a day';
  if (perDay > 1.35) return '1-2 times a day';
  if (perDay >= 1) return 'Daily';

  if (perWeek > 4) return 'Every 1-2 days';
  if (perWeek > 2) return '2-4 times a week';
  if (perWeek > 1) return '1-2 times a week';
  if (perWeek >= 1) return 'Once a week';

  if (perMonth > 2) return 'Every 1-2 weeks';
  if (perMonth >= 1) return 'Every 2-4 weeks';
  if (perMonth > 0.5) return 'Every 4-6 weeks';
  if (perMonth > 0.2) return 'Every few months';

  return 'N/A';
};

export { formatFrequency };
