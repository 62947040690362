/**
 * Map over the incoming raw SEO data from Dato, check if there is
 * an existing match in the customSeoData and replace it if found.
 * We have to do 3 separate checks, one for the title and then 2 others
 * for matching property attributes and matching name attributes
 */

import { find } from 'lodash';
import { OgMetaAttributes, RegularMetaAttributes } from 'react-datocms/src/Seo/types';

import { SeoMetaTagType } from '@/types';

const injectCustomSeoData = (customSeoData: SeoMetaTagType[], datoSeoData: SeoMetaTagType[]): SeoMetaTagType[] => {
  return datoSeoData.map((item) => {
    // Check if the customSeoData has a title
    const matchedTitle = find(customSeoData, { tag: 'title' });

    // Replace the one from the datoSeoData if found
    if (matchedTitle && item.tag === 'title') {
      return { ...matchedTitle };
    }

    // Check to see if any property attribute exists in the customSeoData
    const matchedProperty = find(
      customSeoData,
      (value) => (value.attributes as OgMetaAttributes)?.property === (item.attributes as OgMetaAttributes)?.property,
    );

    // Replace the one from the datoSeoData if found
    if ((item.attributes as OgMetaAttributes)?.property && matchedProperty) {
      return { ...matchedProperty };
    }

    // Check to see if any name attribute exists in the customSeoData
    const matchedName = find(
      customSeoData,
      (value) => (value.attributes as RegularMetaAttributes)?.name === (item.attributes as RegularMetaAttributes)?.name,
    );

    // Replace the one from the datoSeoData if found
    if ((item.attributes as RegularMetaAttributes)?.name && matchedName) {
      return { ...matchedName };
    }

    return { ...item };
  });
};

export { injectCustomSeoData };
