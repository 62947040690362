import { compact, flatten, uniq } from 'lodash';

import { CARRIER_AIRLINE, CARRIER_OCEAN, ID_IATA, ID_ICAO, ID_SCAC } from '@/lib/constants';
import { uldToSearchParam } from '@/lib/models/uld/utils/uld-to-search-param';
import { CargoOption, OptionGroup, OptionItem, RouteInterface } from '@/types';
import {
  CarrierTypes,
  IdentifierApiInterface,
  RouteCarrierApiV2Interface,
  RouteCarriersApiV2Interface,
  RouteLocationsApiV2Interface,
} from '@/types/api-types';
import { getPreferredIdentifier } from '@/utils/helpers';

/**
 * Determines a carrier type airline | ocean based on identifier
 * NOTE: this is dirty and we need a more reliable way to determine this
 * @param identifier IdentifierApiInterface
 * @returns Airline | Ocean Carrier | Other
 */
const getCarrierType = (identifier?: IdentifierApiInterface | null): CarrierTypes => {
  if (identifier) {
    if (identifier.type === ID_SCAC) return CARRIER_OCEAN;
    if (identifier.type === ID_IATA || identifier.type === ID_ICAO) return CARRIER_AIRLINE;
  }
  return 'Other';
};

const isRouteLocation = (
  obj: RouteCarrierApiV2Interface | RouteLocationsApiV2Interface,
): obj is RouteLocationsApiV2Interface => {
  return 'type' in obj && obj.type !== undefined;
};

const groupCarriers = (data: RouteLocationsApiV2Interface | RouteCarriersApiV2Interface) => {
  return Object.entries(data).reduce((res: Record<string, OptionItem[]>, [key, value]) => {
    const result = { ...res };
    if (value.names && value.names.short) {
      const { names, identifiers } = value;
      const identifier = getPreferredIdentifier(identifiers);
      const type = (isRouteLocation(value) ? value.type : getCarrierType(identifier)) as string;

      // Find group
      const group = result[type] || [];
      // push to items
      group.push({
        label: `${names.short?.[0]} (${identifier?.value})`,
        value: key,
      });

      result[type] = group;
    }
    return result;
  }, {});
};

const formatToOptionsGroup = (
  data: RouteLocationsApiV2Interface | RouteCarriersApiV2Interface,
  allLabel: string,
): OptionGroup[] => {
  // Group Locations
  const options = groupCarriers(data);

  // Format into OptionGroup[]
  const groups = Object.entries(options).map(([key, items]) => {
    const sortedItem = items.sort((a, b) => a.label.localeCompare(b.label));
    return {
      title: key,
      items: sortedItem,
    };
  });

  // merge in reset group
  return [
    {
      title: '',
      items: [{ value: 'all', label: allLabel }],
    },
    ...groups,
  ];
};

// Create a an object with all the carriers and locations
// from the displayed routes to be used for filtering
// and remove duplicates
const getRouteCarriers = (
  routes: RouteInterface[],
): {
  carriers: string[];
} => {
  const carriersAndLocations = routes.reduce(
    (acc, route) => {
      const carriersArr = route.blocks.map((block) => block.carriers);
      const flattenedCarriers = compact(flatten(carriersArr));
      return {
        carriers: [...acc.carriers, ...flattenedCarriers],
      };
    },
    { carriers: [] } as {
      carriers: string[];
    },
  );

  // Remove duplicates
  return {
    carriers: uniq(carriersAndLocations.carriers),
  };
};

const cargoToQueryParams = (cargoFilters: CargoOption) => {
  const { uld, ...rest } = cargoFilters;

  return {
    ...rest,
    uldId: uld ? uldToSearchParam(uld) : undefined,
  };
};

export { cargoToQueryParams, formatToOptionsGroup, getCarrierType, getRouteCarriers, groupCarriers };
