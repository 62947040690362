import { IpApiDetailsInterface } from '@/types/api-types';
import { request } from '@/utils/api/request';

const ipapiProvider = {
  // Queries user details by IP address
  query: async (): Promise<IpApiDetailsInterface> => {
    const requestUrl = 'https://ipapi.co/json/';

    // returns data object from response
    return request<IpApiDetailsInterface>(requestUrl).then((result) => result);
  },
};

export { ipapiProvider };
