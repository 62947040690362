/**
 * Spacing
 * https://styled-system.com/api#space
 *
 * In the default array format to allow more values for future proofing
 * and used in specific ways and also in named form for common usage
 *
 * To use with Styled System Components
 *
 * <Heading mb="mid">Heading with a 24px margin on the bottom</Heading>
 *
 * or with different values at breakpoints:
 *
 * <Heading mb="{{ _: 'sm', md: 'lg', xl: 'xxl }}">Heading with a changing margin on the bottom</Heading>
 *
 * or use directly using the named aliases or the space array via the theme:
 *
 * const Component = styled.div`
 *  margin-top: ${themeGet('space.lg')};
 * `;
 *
 */

type Space = string[] & {
  [key: string]: string;
  xs: string;
  sm: string;
  base: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
  xxxxl: string;
};

const space = [
  '0', // 0 / Tailwind 0
  '0.25rem', // 1 / Tailwind 1
  '0.5rem', // 2 / Tailwind 2
  '0.75rem', // 3 / Tailwind 3
  '1rem', // 4 / Tailwind 4
  '1.25rem', // 5 / Tailwind 5
  '1.5rem', // 6 / Tailwind 6
  '2rem', // 7 / Tailwind 8
  '2.5rem', // 8 / Tailwind 10
  '3rem', // 9 / Tailwind 12
  '4rem', // 10 / Tailwind 16
  '5rem', // 11 / Tailwind 20
  '6rem', // 12 / Tailwind 24
  '8rem', // 13 / Tailwind 32
  '10rem', // 14 / Tailwind 40
  '12rem', // 15 / Tailwind 48
  '14rem', // 16 / Tailwind 56
  '16rem', // 17 / Tailwind 64
] as Space;

/**
 * Aliases
 */
/* eslint-disable prefer-destructuring */
space.xs = space[1]; // .25rem / Tailwind 1
space.sm = space[2]; // .5rem / Tailwind 2
space.base = space[4]; // 1rem / Tailwind 4
space.md = space[6]; // 1.5rem / Tailwind 6
space.lg = space[7]; // 2rem / Tailwind 8
space.xl = space[9]; // 3rem / Tailwind 12
space.xxl = space[10]; // 4rem / Tailwind 16
space.xxxl = space[11]; // 5rem / Tailwind 20
space.xxxxl = space[13]; // 8rem / Tailwind 32
/* eslint-enable prefer-destructuring */

export { space };
